import {realmPlugin, createActiveEditorSubscription$} from '@mdxeditor/editor';
import {
  COMMAND_PRIORITY_CRITICAL,
  INSERT_LINE_BREAK_COMMAND,
  KEY_DOWN_COMMAND,
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $insertNodes,
  $isParagraphNode,
  LexicalNode,
  ElementNode,
} from 'lexical';

function isInsideList(node: LexicalNode): boolean {
  let parent = node.getParent<ElementNode>();
  while (parent) {
    // Check for both listitem type and list node type
    if ('__type' in parent && (parent.__type === 'listitem' || parent.__type === 'list')) {
      return true;
    }
    // Also check if the parent has a getListType method (indicating it's a list-related node)
    if ('getListType' in parent && typeof parent.getListType === 'function') {
      return true;
    }
    parent = parent.getParent<ElementNode>();
  }
  return false;
}

export const customEnterPlugin = realmPlugin({
  init(realm) {
    realm.pub(createActiveEditorSubscription$, [
      editor => {
        return editor.registerCommand(
          KEY_DOWN_COMMAND,
          event => {
            if (event.key === 'Enter') {
              const selection = $getSelection();
              if (!$isRangeSelection(selection)) {
                return false;
              }

              const anchor = selection.anchor;
              const anchorNode = anchor.getNode();
              // If we're inside a list, let the default behavior handle it
              if (isInsideList(anchorNode)) {
                return false;
              }

              // Otherwise, handle our custom enter behavior
              event.preventDefault();

              // Check if we're in an empty line
              const isEmptyLine =
                ($isParagraphNode(anchorNode) ||
                  anchorNode.getType().startsWith('heading')) &&
                anchorNode.getTextContent().endsWith('\n');

              if (isEmptyLine) {
                const newParagraph = $createParagraphNode();
                $insertNodes([newParagraph]);
                newParagraph.select();
                return true;
              }

              // Otherwise, insert a normal line break
              editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false);
              return true;
            }
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        );
      },
    ]);
  },
});
