import {SubscriptionStatus} from './GetUsersResponse';

export const stages = [
  {value: 'pregnant', label: 'Pregnant'},
  {value: 'trying_to_conceive', label: 'Trying to Conceive'},
  {value: 'postpartum', label: 'Postpartum'},
];

export const subscriptionStatuses = [
  {value: SubscriptionStatus.FREE, label: 'Free'},
  {value: SubscriptionStatus.ACTIVE, label: 'Active'},
  {value: SubscriptionStatus.CANCELLED, label: 'Canceled'},
  {value: SubscriptionStatus.CANCELLED_TRIAL, label: 'Canceled Trial'},
  {value: SubscriptionStatus.TRIAL, label: 'Trial'},
  {value: SubscriptionStatus.PROMOTIONAL, label: 'Promotional access'},
];

export const subscriptionStatusesMap: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.FREE]: 'Free',
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.CANCELLED]: 'Canceled',
  [SubscriptionStatus.CANCELLED_TRIAL]: 'Canceled Trial',
  [SubscriptionStatus.TRIAL]: 'Trial',
  [SubscriptionStatus.PROMOTIONAL]: 'Promotional access',
};

export const userTypes = [
  {value: 'admin', label: 'Admin'},
  {value: 'end-user', label: 'User'},
];

export const subscriptionStatusStyle: Record<
  string,
  {color: string; backgroundColor: string}
> = {
  [SubscriptionStatus.FREE]: {color: '#1A202C', backgroundColor: '#E2E8F0'},
  [SubscriptionStatus.ACTIVE]: {
    color: '#22543D',
    backgroundColor: '#C6F6D5',
  },
  [SubscriptionStatus.CANCELLED]: {
    color: '#822727',
    backgroundColor: '#FED7D7',
  },
  [SubscriptionStatus.CANCELLED_TRIAL]: {
    color: '#822727',
    backgroundColor: '#FED7D7',
  },
  [SubscriptionStatus.TRIAL]: {
    color: '#2A4365',
    backgroundColor: '#BEE3F8',
  },
};
