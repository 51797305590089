/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Input,
  Grid,
  GridItem,
  FormControl,
  Button,
  FormErrorMessage,
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {useEffect, useState} from 'react';
import UsersTableActionsColumn from '../../../components/ResultsTable/Users/UsersTableActionsColumn.tsx';

import 'react-image-crop/dist/ReactCrop.css';
import {useMutation, useQuery} from '@tanstack/react-query';
import {format} from 'date-fns';
import {AxiosInstance} from '../../../api/_AxiosInstance.ts';
import {SingleUser, SubscriptionStatus} from '../types/GetUsersResponse.ts';
import {Link as ReactRouterLink, useParams} from 'react-router-dom';
import './userDetails.css';
import {isValidEmail} from '../../../helpers/regex.ts';
import {subscriptionStatusesMap} from '../types/usersTypes.ts';

const getStageLabel = (stage: string) => {
  switch (stage) {
    case 'trying_to_conceive':
      return 'Trying To Conceive';
    case 'pregnant':
      return 'Pregnant';
    case 'postpartum':
      return 'Postpartum';
    default:
      return '';
  }
};

const storeLabelMap: Record<string, string> = {
  app_store: 'IOS',
  play_store: 'Android',
};

export default function UserDetailsPage() {
  const {id} = useParams();

  const {data: userData, refetch} = useQuery({
    queryKey: [id],
    queryFn: async () => {
      const {data} = await AxiosInstance.get<SingleUser>(`/user/${id}`);
      return data;
    },
  });

  const updateEmailMutation = useMutation({
    mutationFn: async () => {
      await AxiosInstance.post(`/user/${id}/email`, {email: email});
    },
    onError: () => {
      if (userData?.email) {
        setEmail(userData?.email);
      }
    },
    onSuccess: async () => {
      await refetch();
      window.toast({
        description: 'Email updated successfully.',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const [email, setEmail] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (userData?.email && userData.email !== email && isValidEmail(email)) {
      setSaveDisabled(false);
    } else if (!saveDisabled) {
      setSaveDisabled(true);
    }
  }, [email, userData]);

  useEffect(() => {
    if (userData?.email) {
      setEmail(userData.email);
    }
  }, [userData]);

  const saveChanges = () => {
    updateEmailMutation.mutate();
  };

  const revenueCatData = userData?.revenueCatData?.subscriber;

  const getActiveSubscription = () => {
    const prem = revenueCatData?.entitlements?.Premium;
    if (prem) {
      return revenueCatData.subscriptions[prem.product_identifier];
    }
    return null;
  };

  const getV2SubscriptionData = () => {
    const subscription = userData?.v2Subscriptions?.items?.[0];

    if (!subscription) {
      return {
        totalRevenueUsd: null,
        country: '',
      };
    }

    const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

    return {
      totalRevenueUsd: subscription.total_revenue_in_usd?.gross,
      country: regionNames.of(subscription.country as string),
    };
  };

  return (
    <Box height="auto" maxWidth={1200}>
      <Flex justifyContent="space-between">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={ReactRouterLink} to="/users" color="gray.500">
              Users
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#" color="gray.500">
              View
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex>
          {userData && (
            <UsersTableActionsColumn
              user={userData}
              changeUserRole={() => refetch()}
              onSubscriptionChange={() => refetch()}
              displayAsMenu
            />
          )}
          <Button
            onClick={saveChanges}
            colorScheme="black"
            isDisabled={saveDisabled}
            ml={2}>
            Save
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Avatar
          src={userData?.profilePictureUrl ? userData.profilePictureUrl : ''}
          name={
            userData?.firstName && userData?.lastName
              ? `${userData?.firstName} ${userData.lastName}`
              : ''
          }
          width="42px"
          height="42px"
          mr={4}
        />
        <Heading mb={4}>{`${userData?.firstName} ${userData?.lastName}`}</Heading>
      </Flex>
      <Card>
        <CardBody>
          {userData && (
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(2, 1fr)"
              gap={5}
              maxWidth="container.xl">
              <GridItem colSpan={2}>
                <Text
                  color="#64676B"
                  fontSize="20px"
                  fontFamily="Outfit"
                  fontWeight="400"
                  lineHeight="24px">
                  User info
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Name
                </Heading>
                <Input
                  placeholder="Name"
                  value={`${userData.firstName} ${userData.lastName}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Email
                </Heading>
                <FormControl isInvalid={!!(email && !isValidEmail(email))}>
                  <Input
                    placeholder="Enter email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={'user-details-input'}
                    type="email"
                  />
                  <FormErrorMessage>Email is invalid</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Stage
                </Heading>
                <Input
                  placeholder="Stage"
                  value={`${getStageLabel(userData?.currentStage)}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Role
                </Heading>
                <Input
                  placeholder="Role"
                  value={`${userData?.role === 'admin' ? 'Admin' : 'User'}`}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Country
                </Heading>
                <Input
                  value={getV2SubscriptionData()?.country ?? ''}
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Currency
                </Heading>
                <Input
                  value={
                    getActiveSubscription()?.price?.currency
                      ? new Intl.DisplayNames('en', {type: 'currency'}).of(
                          getActiveSubscription()!.price.currency,
                        )
                      : ''
                  }
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Sign-up date
                </Heading>
                <Input
                  placeholder="Sign-up date"
                  value={
                    revenueCatData?.first_seen
                      ? format(new Date(revenueCatData?.first_seen), 'MM/dd/yyyy')
                      : ''
                  }
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>

              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Last login
                </Heading>
                <Input
                  placeholder="Last login"
                  value={
                    revenueCatData?.last_seen
                      ? format(new Date(revenueCatData?.last_seen), 'MM/dd/yyyy')
                      : ''
                  }
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>

              {userData?.subscriptionStatus === SubscriptionStatus.ACTIVE && (
                <>
                  <GridItem colSpan={2}>
                    <Text
                      color="#64676B"
                      fontSize="20px"
                      fontFamily="Outfit"
                      fontWeight="400"
                      lineHeight="24px">
                      Subscription
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Status
                    </Heading>
                    <Input
                      backgroundColor="#C6F6D5"
                      color="#15803D"
                      placeholder="Status"
                      value={subscriptionStatusesMap[userData.subscriptionStatus]}
                      mb={5}
                      disabled
                      _disabled={{
                        backgroundColor: '#C6F6D5',
                      }}
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Membership type
                    </Heading>
                    <Input
                      placeholder="Membership type"
                      value={getActiveSubscription()?.display_name ?? ''}
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Membership start date
                    </Heading>
                    <Input
                      placeholder="Membership start date"
                      value={
                        getActiveSubscription()?.purchase_date
                          ? format(
                              new Date(getActiveSubscription()!.purchase_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Membership end date
                    </Heading>
                    <Input
                      placeholder="Membership end date"
                      value={
                        getActiveSubscription()?.expires_date
                          ? format(
                              new Date(getActiveSubscription()!.expires_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Total revenue spent in USD
                    </Heading>
                    <Input
                      placeholder="Total revenue spent "
                      value={
                        getV2SubscriptionData().totalRevenueUsd !== null
                          ? `$${getV2SubscriptionData()?.totalRevenueUsd} USD`
                          : 'N/A'
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                </>
              )}

              {userData?.subscriptionStatus === SubscriptionStatus.TRIAL && (
                <>
                  <GridItem colSpan={2}>
                    <Text
                      color="#64676B"
                      fontSize="20px"
                      fontFamily="Outfit"
                      fontWeight="400"
                      lineHeight="24px">
                      Subscription
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Status
                    </Heading>
                    <Input
                      backgroundColor="#BEE3F8"
                      color="#2A4365"
                      placeholder="Status"
                      value={subscriptionStatusesMap[userData.subscriptionStatus]}
                      mb={5}
                      disabled
                      _disabled={{
                        backgroundColor: '#BEE3F8',
                      }}
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Target subscription type
                    </Heading>
                    <Input
                      placeholder="Target subscription type"
                      value={getActiveSubscription()?.display_name ?? ''}
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Trial start date
                    </Heading>
                    <Input
                      placeholder="Trial start date"
                      value={
                        getActiveSubscription()?.purchase_date
                          ? format(
                              new Date(getActiveSubscription()!.purchase_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Conversion date
                    </Heading>
                    <Input
                      placeholder="Conversion date"
                      value={
                        getActiveSubscription()?.expires_date
                          ? format(
                              new Date(getActiveSubscription()!.expires_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Total revenue spent in USD
                    </Heading>
                    <Input
                      placeholder="Total revenue spent "
                      value={
                        getV2SubscriptionData().totalRevenueUsd !== null
                          ? `$${getV2SubscriptionData()?.totalRevenueUsd} USD`
                          : 'N/A'
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                </>
              )}

              {userData?.subscriptionStatus === SubscriptionStatus.CANCELLED && (
                <>
                  <GridItem colSpan={2}>
                    <Text
                      color="#64676B"
                      fontSize="20px"
                      fontFamily="Outfit"
                      fontWeight="400"
                      lineHeight="24px">
                      Subscription
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Status
                    </Heading>
                    <Input
                      backgroundColor="#FED7D7"
                      color="#822727"
                      placeholder="Status"
                      value={subscriptionStatusesMap[userData.subscriptionStatus]}
                      mb={5}
                      disabled
                      _disabled={{
                        backgroundColor: '#FED7D7',
                      }}
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Subscription type
                    </Heading>
                    <Input
                      placeholder="Subscription type"
                      value={getActiveSubscription()?.display_name ?? ''}
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Cancellation date
                    </Heading>
                    <Input
                      placeholder="Cancellation date"
                      value={
                        getActiveSubscription()?.unsubscribe_detected_at
                          ? format(
                              new Date(getActiveSubscription()!.unsubscribe_detected_at),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Expiration date
                    </Heading>
                    <Input
                      placeholder="Expiration date"
                      value={
                        getActiveSubscription()?.expires_date
                          ? format(
                              new Date(getActiveSubscription()!.expires_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Purchase date
                    </Heading>
                    <Input
                      placeholder="Purchase date"
                      value={
                        getActiveSubscription()?.purchase_date
                          ? format(
                              new Date(getActiveSubscription()!.purchase_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Total revenue spent in USD
                    </Heading>
                    <Input
                      placeholder="Total revenue spent "
                      value={
                        getV2SubscriptionData().totalRevenueUsd !== null
                          ? `$${getV2SubscriptionData()?.totalRevenueUsd} USD`
                          : 'N/A'
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                </>
              )}

              {userData?.subscriptionStatus === SubscriptionStatus.CANCELLED_TRIAL && (
                <>
                  <GridItem colSpan={2}>
                    <Text
                      color="#64676B"
                      fontSize="20px"
                      fontFamily="Outfit"
                      fontWeight="400"
                      lineHeight="24px">
                      Subscription
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Status
                    </Heading>
                    <Input
                      backgroundColor="#FED7D7"
                      color="#822727"
                      placeholder="Status"
                      value={subscriptionStatusesMap[userData.subscriptionStatus]}
                      mb={5}
                      disabled
                      _disabled={{
                        backgroundColor: '#FED7D7',
                      }}
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Target subscription type
                    </Heading>
                    <Input
                      placeholder="Target subscription type"
                      value={getActiveSubscription()?.display_name ?? ''}
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Cancellation date
                    </Heading>
                    <Input
                      placeholder="Cancellation date"
                      value={
                        getActiveSubscription()?.unsubscribe_detected_at
                          ? format(
                              new Date(getActiveSubscription()!.unsubscribe_detected_at),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Expiration date
                    </Heading>
                    <Input
                      placeholder="Expiration date"
                      value={
                        getActiveSubscription()?.expires_date
                          ? format(
                              new Date(getActiveSubscription()!.expires_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Trial start date
                    </Heading>
                    <Input
                      placeholder="Trial start date"
                      value={
                        getActiveSubscription()?.purchase_date
                          ? format(
                              new Date(getActiveSubscription()!.purchase_date),
                              'MM/dd/yyyy',
                            )
                          : ''
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Total revenue spent in USD
                    </Heading>
                    <Input
                      placeholder="Total revenue spent "
                      value={
                        getV2SubscriptionData().totalRevenueUsd !== null
                          ? `$${getV2SubscriptionData()?.totalRevenueUsd} USD`
                          : 'N/A'
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                </>
              )}

              {userData?.subscriptionStatus === SubscriptionStatus.FREE && (
                <>
                  <GridItem colSpan={2}>
                    <Text
                      color="#64676B"
                      fontSize="20px"
                      fontFamily="Outfit"
                      fontWeight="400"
                      lineHeight="24px">
                      Subscription
                    </Text>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Status
                    </Heading>
                    {userData.hasPromotionalSubscription && (
                      <Input
                        backgroundColor="#C6F6D5"
                        color="#15803D"
                        placeholder="Status"
                        value="Promotional Subscription"
                        mb={5}
                        disabled
                        _disabled={{
                          backgroundColor: '#C6F6D5',
                        }}
                        className={'user-details-input'}
                      />
                    )}

                    {!userData.hasPromotionalSubscription && (
                      <Input
                        backgroundColor="#E2E8F0"
                        color="#1A202C"
                        placeholder="Status"
                        value="Free"
                        mb={5}
                        disabled
                        _disabled={{
                          backgroundColor: '#E2E8F0',
                        }}
                        className={'user-details-input'}
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Heading size="sm" pb={3}>
                      Total revenue spent in USD
                    </Heading>
                    <Input
                      placeholder="Total revenue spent"
                      value={
                        getV2SubscriptionData().totalRevenueUsd !== null
                          ? `$${getV2SubscriptionData()?.totalRevenueUsd} USD`
                          : 'N/A'
                      }
                      mb={5}
                      disabled
                      className={'user-details-input'}
                    />
                  </GridItem>
                </>
              )}

              <GridItem colSpan={1}>
                <Heading size="sm" pb={3}>
                  Device type
                </Heading>
                <Input
                  placeholder="Device type"
                  value={
                    getActiveSubscription()
                      ? storeLabelMap[getActiveSubscription()!.store]
                      : 'N/A'
                  }
                  mb={5}
                  disabled
                  className={'user-details-input'}
                />
              </GridItem>
            </Grid>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
