import React from 'react';
import {Flex, Text, Box, Divider} from '@chakra-ui/react';

interface StatItemProps {
  label: string;
  value: string | number;
  valueColor?: string;
}

const StatItem: React.FC<StatItemProps> = ({label, value, valueColor = '#8E959E'}) => (
  <Flex
    flex="1"
    p={6}
    bg="white"
    flexDirection="column"
    justifyContent="flex-start"
    alignItems="flex-start">
    <Flex w="100%" alignItems="center">
      <Text color="#64676B" fontSize="12px" fontWeight={600} lineHeight="18px">
        {label}
      </Text>
    </Flex>
    <Flex
      w="100%"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start">
      <Text w="100%" color={valueColor} fontSize="24px" fontWeight={700} lineHeight="28px">
        {value}
      </Text>
    </Flex>
  </Flex>
);

interface StatsDisplayProps {
  stats: {
    label: string;
    value: string | number;
    status: string;
  }[];
}

const StatsDisplay: React.FC<StatsDisplayProps> = ({stats}) => {
  const getColorByStatus = (status: string) => {
    switch (status) {
      case 'active':
        return '#22C55E';
      case 'cancelled':
      case 'cancelled_trial':
        return '#D56951';
      default:
        return '#8E959E';
    }
  };

  return (
    <Flex justifyContent="flex-start" alignItems="center" bg="white" px={4}>
      {stats.map((stat, index) => (
        <React.Fragment key={stat.label}>
          <StatItem
            label={stat.label}
            value={stat.value}
            valueColor={getColorByStatus(stat.status)}
          />
          {index < stats.length - 1 && (
            <Divider orientation="vertical" borderColor="#CFD6E1" mx={0} h="32px" />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default StatsDisplay;
