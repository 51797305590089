// WeekComponent.tsx
import React from 'react';
import {
  Box,
  Flex,
  Input,
  IconButton,
  Button,
  HStack,
  Collapse,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronRightIcon, AddIcon, CloseIcon} from '@chakra-ui/icons';
import {FiMoreVertical} from 'react-icons/fi'; // Import three dots icon
import {Droppable} from 'react-beautiful-dnd';
import DayComponent from './DayComponent.tsx';
import {Video} from '../../types/Video.ts';
import {Week} from '../../types/Week.ts';
import {Step} from '../../types/Step.ts';

interface WeekProps {
  week: Week;
  index: number;
  toggleWeekExpansion: (weekUuid: string) => void;
  handleAddDay: (weekUuid: string) => void;
  handleAddVideoSteps: (dayUuid: string, videos: Video[]) => void;
  handleAddTextStep: (dayUuid: string) => void;
  handleAddSurveyStep: (dayUuid: string) => void;
  handleDeleteWeek: (weekUuid: string) => void;
  handleDeleteDay: (dayUuid: string) => void;
  handleDeleteStep: (dayUuid: string, stepId: string) => void;
  handleUpdateWeekTitle: (weekUuid: string, title: string) => void;
  handleUpdateDayTitle: (dayUuid: string, title: string) => void;
  stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
  handleEditStep: (dayUuid: string, stepUuid: string, step: Step) => void;
  handleGoToVideo: (videoId: number) => void;
  handleVideoStepTitle: (dayUuid: string, stepUuid: string, title: string) => void;
}

const WeekComponent: React.FC<WeekProps> = ({
  week,
  index,
  toggleWeekExpansion,
  handleAddDay,
  handleAddVideoSteps,
  handleAddTextStep,
  handleAddSurveyStep,
  handleDeleteWeek,
  handleDeleteDay,
  handleDeleteStep,
  handleUpdateWeekTitle,
  handleUpdateDayTitle,
  stage,
  handleEditStep,
  handleGoToVideo,
  handleVideoStepTitle,
}) => (
  <Box
    key={week.id}
    p={6}
    bg="rgba(230, 234, 241, 0.20)"
    borderRadius="16px"
    border="1px solid #E6EAF1">
    <Flex justify="space-between" align="center" my={2}>
      <HStack spacing={2}>
        <IconButton
          icon={week.expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          size="sm"
          aria-label="Expand/Collapse"
          onClick={() => toggleWeekExpansion(week.uuid)}
        />
        <Input
          value={week.title} // Controlled input
          onChange={e => handleUpdateWeekTitle(week.uuid, e.target.value)} // Update handler
          placeholder={`Week ${index + 1}`} // Placeholder using index
          size="sm"
        />
      </HStack>
      <HStack spacing={2}>
        <Button size="sm" leftIcon={<AddIcon />} onClick={() => handleAddDay(week.uuid)}>
          Add day
        </Button>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FiMoreVertical />}
            size="sm"
            aria-label="Options"
          />
          <MenuList>
            <MenuItem icon={<CloseIcon />} onClick={() => handleDeleteWeek(week.uuid)}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
    <Collapse in={week.expanded}>
      <Droppable droppableId={week.uuid} type="day" direction="vertical">
        {provided => (
          <VStack
            {...provided.droppableProps}
            ref={provided.innerRef}
            spacing={4}
            align="stretch">
            {week.days.map((day, index) => (
              <DayComponent
                key={day.uuid}
                day={day}
                index={index}
                handleAddSurveyStep={handleAddSurveyStep}
                handleAddVideoSteps={handleAddVideoSteps}
                handleAddTextStep={handleAddTextStep}
                handleDeleteDay={handleDeleteDay}
                handleDeleteStep={handleDeleteStep}
                handleUpdateDayTitle={handleUpdateDayTitle}
                stage={stage}
                handleEditStep={handleEditStep}
                handleGoToVideo={handleGoToVideo}
                handleVideoStepTitle={handleVideoStepTitle}
              />
            ))}
            {provided.placeholder}
          </VStack>
        )}
      </Droppable>
    </Collapse>
  </Box>
);

export default WeekComponent;
