export interface GetUsersResponse {
  data: SingleUser[];
  total: number;
  page: number;
  lastPage: number;
}

export enum SubscriptionStatus {
  FREE = 'free',
  TRIAL = 'trial',
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  CANCELLED_TRIAL = 'cancelled_trial',
  PROMOTIONAL = 'promotional',
}

export interface RcCustomerInfo {
  request_date: string;
  request_date_ms: number;
  subscriber: Subscriber;
}

export interface Subscriber {
  entitlements: Record<string, Entitlement>;
  first_seen: string;
  last_seen?: string;
  management_url: string;
  non_subscriptions: any;
  original_app_user_id: string;
  original_application_version: string;
  original_purchase_date: string;
  subscriptions: Record<string, Subscription>;
}

export interface Entitlement {
  expires_date: any;
  grace_period_expires_date: any;
  product_identifier: string;
  purchase_date: string;
}

export interface Subscription {
  auto_resume_date: any;
  billing_issues_detected_at: any;
  expires_date: string;
  grace_period_expires_date: any;
  is_sandbox: boolean;
  original_purchase_date: string;
  ownership_type: string;
  period_type: string;
  purchase_date: string;
  refunded_at: any;
  store: string;
  unsubscribe_detected_at: string;
  display_name: string;
  price: {
    amount: number;
    currency: string;
  };
}

export interface SingleUser {
  id: number;
  supabaseUID: string;
  email: string;
  firstName?: string;
  lastName?: string;
  currentStage: string;
  role?: string;
  subscription?: any;
  hasSubscription: boolean;
  hasPromotionalSubscription: boolean;
  isRevenueCatPremium: boolean;
  profilePictureUrl?: string;
  subscriptionStatus: SubscriptionStatus;
  revenueCatData: RcCustomerInfo;
  v2Subscriptions: any;
}
