import React, {useState, useEffect, useCallback} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Stack,
  ModalFooter,
  Button,
  Avatar,
} from '@chakra-ui/react';
import {Select, SingleValue} from 'chakra-react-select';
import {UserRepository} from '@amityco/ts-sdk';
import useDebounce from '../../helpers/useDebounce';

export interface AddMentionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (value: ISearchItem) => void;
}

export interface ISearchItem {
  targetId: string;
  targetType: string;
  displayName: string;
  categoryIds?: string[];
  avatarFileId?: string;
  avatar?: string;
  value: string;
  label: string;
}

interface SelectOption {
  value: string;
  label: string;
  data: ISearchItem;
}

const AddMentionModal: React.FC<AddMentionModalProps> = ({isOpen, onClose, onSelect}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMention, setSelectedMention] = useState<ISearchItem | null>(null);
  const debouncedUserName = useDebounce(searchQuery, 500);
  const [usersObject, setUsersObject] = useState<Amity.LiveCollection<Amity.User>>();
  const [searchList, setSearchList] = useState<ISearchItem[]>([]);

  const {
    data: userArr = [],
    onNextPage,
    loading: isLoading,
    hasNextPage,
  } = usersObject ?? {};

  const searchAccounts = useCallback((text = '') => {
    let searchProps = {};
    if (text.length > 2) {
      searchProps = {displayName: text, limit: 10, sortBy: 'displayName'};
    } else {
      searchProps = {limit: 10, sortBy: 'displayName'};
    }
    const unsubscribe = UserRepository.getUsers(searchProps, data => {
      setUsersObject(data);
    });
    unsubscribe();
  }, []);

  useEffect(() => {
    searchAccounts(debouncedUserName);
  }, [debouncedUserName, searchAccounts]);

  useEffect(() => {
    if (!isLoading) {
      const searchUsers: ISearchItem[] = userArr.map(item => ({
        targetId: item?.userId,
        targetType: 'user',
        avatarFileId: item?.avatarFileId ?? '',
        displayName: item.displayName!,
        avatar: item.avatarCustomUrl!,
        value: item.userId,
        label: item.displayName!,
      }));
      setSearchList(searchUsers);
    }
  }, [userArr, isLoading]);

  function onSelectOption(selectedOption: SingleValue<ISearchItem>) {
    setSelectedMention(selectedOption);
  }

  function handleAddClick() {
    if (selectedMention) {
      onSelect(selectedMention);
    }
    onClose();
  }

  const CustomOption = ({innerProps, data}: {innerProps: any; data: ISearchItem}) => (
    <Box {...innerProps} p={2} _hover={{bg: 'gray.100'}} cursor="pointer">
      <Stack direction="row" spacing={2} align="center">
        <Avatar size="sm" src={data.avatar} name={data.label} />
        <Text>{data.label}</Text>
      </Stack>
    </Box>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add mention</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Select
            options={searchList}
            isLoading={isLoading}
            onInputChange={setSearchQuery}
            onChange={onSelectOption}
            components={{Option: CustomOption}}
            placeholder="Search users..."
            isClearable
            isSearchable
            chakraStyles={{
              container: provided => ({
                ...provided,
                width: '100%',
              }),
            }}
            onMenuScrollToBottom={() => hasNextPage && onNextPage?.()}
            noOptionsMessage={({inputValue}) =>
              inputValue.length > 0 ? 'No users found' : 'Type to search users'
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" isDisabled={!selectedMention} onClick={handleAddClick}>
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMentionModal;
