// DayComponent.tsx
import React from 'react';
import {
  Box,
  Flex,
  Input,
  IconButton,
  VStack,
  Button,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import {DragHandleIcon, AddIcon, CloseIcon} from '@chakra-ui/icons';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import StepComponent from './StepComponent.tsx';
import {FiMoreVertical} from 'react-icons/fi';
import VideoListModal from './VideoListModal.tsx';
import {Video} from '../../types/Video.ts';
import {Day} from '../../types/Day.ts';
import {Step} from '../../types/Step.ts';

interface DayProps {
  day: Day;
  index: number;
  handleAddVideoSteps: (dayUuid: string, videos: Video[]) => void;
  handleAddTextStep: (dayUuid: string) => void;
  handleAddSurveyStep: (dayUuid: string) => void;
  handleDeleteDay: (dayUuid: string) => void;
  handleDeleteStep: (dayUuid: string, stepId: string) => void;
  handleUpdateDayTitle: (dayUuid: string, title: string) => void;
  stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
  handleEditStep: (dayUuid: string, stepUuid: string, step: Step) => void;
  handleGoToVideo: (videoId: number) => void;
  handleVideoStepTitle: (dayUuid: string, stepUuid: string, title: string) => void;
}

const DayComponent: React.FC<DayProps> = ({
  day,
  index,
  handleAddVideoSteps,
  handleAddTextStep,
  handleAddSurveyStep,
  handleDeleteDay,
  handleDeleteStep,
  handleUpdateDayTitle,
  stage,
  handleEditStep,
  handleGoToVideo,
  handleVideoStepTitle,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const dayStepsDurationMinutes = Math.floor(
    day.steps.reduce((acc, step) => acc + parseInt(step.duration), 0) / 60,
  );
  const dayStepsDurationSeconds =
    day.steps.reduce((acc, step) => acc + parseInt(step.duration), 0) % 60;

  return (
    <>
      <VideoListModal
        isOpen={isOpen}
        onClose={onClose}
        handleAddVideoSteps={handleAddVideoSteps}
        dayUuid={day.uuid}
        stage={stage}
      />
      <Draggable draggableId={day.uuid} index={index} key={day.uuid}>
        {provided => (
          <Box
            {...provided.draggableProps}
            ref={provided.innerRef}
            p={6}
            bg="white"
            borderRadius="16px"
            border="1px solid #E6EAF1">
            <Flex align="center" mb={4}>
              <IconButton
                icon={<DragHandleIcon />}
                size="sm"
                aria-label="Drag handle"
                {...provided.dragHandleProps}
              />
              <Input
                value={day.title} // Controlled input
                onChange={e => handleUpdateDayTitle(day.uuid, e.target.value)} // Update handler
                placeholder={`Day ${index + 1}`}
                size="sm"
                ml={2}
              />
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<FiMoreVertical />}
                  size="sm"
                  aria-label="Options"
                  ml={2}
                />
                <MenuList>
                  <MenuItem icon={<CloseIcon />} onClick={() => handleDeleteDay(day.uuid)}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Droppable droppableId={day.uuid} type="step" direction="vertical">
              {provided => (
                <VStack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={4}
                  align="stretch"
                  ml={4}>
                  {day.steps.map((step, index) => (
                    <StepComponent
                      key={step.uuid}
                      step={step}
                      index={index}
                      handleDeleteStep={handleDeleteStep}
                      dayId={day.uuid}
                      handleEditStep={handleEditStep}
                      handleGoToVideo={handleGoToVideo}
                      handleVideoStepTitle={handleVideoStepTitle}
                    />
                  ))}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
            <HStack justifyContent="center" mt={4}>
              <Box>
                <Tooltip
                  label={!stage ? 'Please select a stage before adding a video step.' : ''}>
                  <Button
                    size="sm"
                    leftIcon={<AddIcon />}
                    onClick={onOpen}
                    isDisabled={!stage}>
                    Add video step
                  </Button>
                </Tooltip>
              </Box>
              <Button
                size="sm"
                leftIcon={<AddIcon />}
                onClick={() => handleAddTextStep(day.uuid)}>
                Add text step
              </Button>
              <Button
                size="sm"
                leftIcon={<AddIcon />}
                onClick={() => handleAddSurveyStep(day.uuid)}>
                Add Survey
              </Button>
            </HStack>
            <Text textAlign="center" color="#64676B" fontSize="12px" mt={4}>
              Total time: {dayStepsDurationMinutes}:
              {dayStepsDurationSeconds.toString().padStart(2, '0')} min
            </Text>
          </Box>
        )}
      </Draggable>
    </>
  );
};

export default DayComponent;
