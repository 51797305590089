/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import React, {useCallback} from 'react';
import {Avatar, Text, HStack} from '@chakra-ui/react';
import {Mention, MentionsInput} from 'react-mentions';
import {UserRepository} from '@amityco/ts-sdk';

const mentionInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      // fontFamily: 'monospace',
      minHeight: 60,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      borderRadius: '8px',
      borderColor: 'gray',
      borderWidth: 1,
      width: '100%',
      padding: '0.5rem',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
    },
    item: {
      padding: '5px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export interface AmityMentionInputProps {
  editText: string;
  onEditTextChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
}

export default function AmityMentionInput(props: AmityMentionInputProps) {
  const {editText, disabled, onEditTextChange} = props;
  const searchAccounts = useCallback((text = '', callback: any) => {
    const unsubscribe = UserRepository.getUsers(
      {displayName: text, limit: 6, sortBy: 'displayName'},
      data => {
        const mappedData = data.data.map(item => ({
          id: item?.userId,
          targetType: 'user',
          avatarFileId: item?.avatarFileId ?? '',
          display: item?.displayName,
          avatar: item.avatarCustomUrl,
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        callback(mappedData);
      },
    );
    unsubscribe();
  }, []);

  return (
    <MentionsInput
      placeholder="Add a comment..."
      value={editText}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onEditTextChange?.(e)}
      style={{
        ...mentionInputStyle,
        width: '100%',
      }}
      a11ySuggestionsListLabel={'Suggested mentions'}
      allowSuggestionsAboveCursor
      disabled={disabled}>
      <Mention
        style={{backgroundColor: '#cee4e5'}}
        data={searchAccounts}
        renderSuggestion={(suggestion: any, search: any) => {
          return (
            <HStack bg="white" borderRadius="lg" boxShadow="sm" p={2} width={'100%'}>
              <Avatar
                src={suggestion.avatar}
                // alt={suggestion.display as string}
                boxSize="24px"
                borderRadius="full"
                mr={1}
              />
              <Text fontSize="sm" color="gray.700">
                {suggestion.display}
              </Text>
            </HStack>
          );
        }}
      />
    </MentionsInput>
  );
}
