// StepComponent.tsx
import React, {useState} from 'react';
import {Box, Text, IconButton, HStack, Image, Flex, Badge, Input} from '@chakra-ui/react';
import {CloseIcon, DragHandleIcon, LinkIcon} from '@chakra-ui/icons';
import {Draggable} from 'react-beautiful-dnd';
import {Step} from '../../types/Step.ts';
import {useParams} from 'react-router-dom';
import {EnvConfig} from '../../base/EnvConfig.ts';
import feeback from '../../assets/images/feedback.svg';

interface StepProps {
  step: Step;
  index: number;
  dayId: string;
  handleDeleteStep: (dayUuid: string, stepUuid: string) => void;
  handleEditStep: (dayUuid: string, stepUuid: string, step: Step) => void;
  handleGoToVideo: (videoId: number) => void;
  handleVideoStepTitle: (dayUuid: string, stepUuid: string, title: string) => void;
}

const StepComponent: React.FC<StepProps> = ({
  step,
  index,
  dayId,
  handleDeleteStep,
  handleEditStep,
  handleGoToVideo,
  handleVideoStepTitle,
}) => {
  const [title, setTitle] = useState(step.title || '');
  const {id} = useParams();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value);
  };

  //change video title
  const handleBlur = () => {
    handleVideoStepTitle(dayId, step.uuid, title);
  };
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      window.toast({
        description: 'Link copied to clipboard',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 3000,
      });
      // You can also trigger a UI notification here
    } catch (err) {
      console.error('Failed to copy text to clipboard', err);
    }
  };

  const renderStepContent = (step: Step) => {
    if (step.type === 'text') {
      return (
        <Box onClick={() => handleEditStep(dayId, step.uuid, step)} cursor="pointer">
          <Text fontWeight="400">{step.title}</Text>
        </Box>
      );
    } else {
      return (
        <Input
          size="sm"
          value={title}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
        />
      );
    }
  };

  return (
    <Draggable draggableId={step.uuid} index={index} key={step.uuid}>
      {provided => (
        <HStack
          {...provided.draggableProps}
          ref={provided.innerRef}
          spacing={4}
          align="center">
          <IconButton
            icon={<DragHandleIcon />}
            size="sm"
            aria-label="Drag handle"
            {...provided.dragHandleProps}
          />
          <Box
            border="1px solid #E6EAF1"
            bg="white"
            p={2}
            borderRadius={5}
            width="100%"
            style={{position: 'relative'}}>
            {step.type === 'video' && <Text>{step.video?.title}</Text>}
            <Flex flex="1" alignItems={'center'}>
              {step.type === 'survey' && (
                <Flex
                  backgroundColor={'#F4DCCB'}
                  borderRadius={8}
                  alignItems={'center'}
                  justifyContent={'center'}
                  h="80px"
                  w="80px">
                  <Image src={feeback} alt="survey" />
                </Flex>
              )}
              {step.type === 'video' && (
                <Box onClick={() => handleGoToVideo(step.videoId!)} cursor="pointer">
                  <Image
                    src={
                      (step as any).videoThumbnails?.thumbnail ??
                      step.videoThumbnail ??
                      undefined
                    }
                    height="80px"
                    width="80px"
                    objectFit="cover"
                    borderRadius={5}
                    alt={step.title}
                  />
                </Box>
              )}
              {step.type === 'text' &&
                (step.textStepThumbnails || step.textStepThumbnailUrl) && (
                  <Image
                    src={
                      step.textStepThumbnails?.thumbnail ??
                      step.textStepThumbnailUrl ??
                      undefined
                    }
                    height="80px"
                    width="80px"
                    objectFit="cover"
                    borderRadius={5}
                    alt={step.title}
                    onClick={() => handleEditStep(dayId, step.uuid, step)}
                    cursor="pointer"
                    // fallback={}
                  />
                )}
              <Box flex="1" p={2} borderRadius="8px">
                {step.type === 'survey' ? (
                  <Text fontWeight="400">Trigger feedback survey</Text>
                ) : (
                  renderStepContent(step)
                )}
                {step.type === 'video' && (
                  <>
                    <Text color="#64676B" fontSize="12px">
                      {Math.floor(parseInt(step.duration, 10) / 60)}:
                      {(parseInt(step.duration, 10) % 60).toString().padStart(2, '0')} min
                    </Text>
                    <Badge colorScheme={step.published ? 'green' : 'gray'}>
                      {step.published ? 'PUBLISHED' : 'UNPUBLISHED'}
                    </Badge>
                  </>
                )}
              </Box>
            </Flex>
            {!step.textStepThumbnails && step.textStepThumbnailUrl && (
              <Text mt={2}>Thumbnail has not been reuploaded</Text>
            )}
            {!(step as any).videoThumbnails?.thumbnail && step.videoThumbnail && (
              <Text mt={2}>Thumbnail has not been reuploaded</Text>
            )}
            {step.published ?? step.type === 'text' ? (
              <IconButton
                icon={<LinkIcon />}
                size="sm"
                aria-label="Share"
                onClick={() =>
                  copyToClipboard(
                    `${EnvConfig.MOBILE_DEEP_LINK_DOMAIN}://program?id=${id}&step=${step.uuid}`,
                  )
                }
                style={{position: 'absolute', right: '10px', bottom: '10px'}}
                variant="ghost"
                _hover={{bg: 'transparent'}}
                _active={{bg: 'transparent'}}
                fontSize={12}
                color="gray.500"
              />
            ) : null}
          </Box>
          <IconButton
            icon={<CloseIcon />}
            size="sm"
            aria-label="Remove"
            onClick={() => handleDeleteStep(dayId, step.uuid)}
            variant="ghost"
            _hover={{bg: 'transparent'}}
            _active={{bg: 'transparent'}}
            fontSize={10}
            color="gray.500"
          />
        </HStack>
      )}
    </Draggable>
  );
};

export default StepComponent;
