import {
  Box,
  Text,
  IconButton,
  HStack,
  Link as ChakraLink,
  useDisclosure,
} from '@chakra-ui/react';
import React, {useEffect} from 'react';

import 'react-image-crop/dist/ReactCrop.css';

import {Link as ReactRouterLink} from 'react-router-dom';
import {DeleteIcon} from '@chakra-ui/icons';
import {FaPencilAlt} from 'react-icons/fa';
import {useMutation} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {SystemPost} from '../../types/Communities/SystemPost.ts';
import DeleteConfirmPopup from '../../components/Common/DeleteConfirmPopup.tsx';
import {
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  MDXEditor,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import {format} from 'date-fns';
import {replaceMentionValues} from '../../helpers/mentionHelpers.ts';

export interface SystemPostComponentProps {
  header: string;
  posts: SystemPost[];
  loading?: boolean;
  onDeletePost: (post: SystemPost) => void;
}

export const SystemPostComponent: React.FC<SystemPostComponentProps> = props => {
  const {header, loading, posts = [], onDeletePost} = props;

  function getMainContent() {
    if (loading) {
      return (
        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight="400"
          color="#8E959E"
          mt={6}
          textAlign="center">
          Loading...
        </Text>
      );
    }
    if (posts.length === 0) {
      return (
        <Text
          fontSize="16px"
          lineHeight="24px"
          fontWeight="400"
          color="#8E959E"
          mt={6}
          textAlign="center">
          No posts
        </Text>
      );
    }

    return (
      <>
        {posts.map(post => (
          <SystemPostDetails key={post.id} post={post} onDeletePost={onDeletePost} />
        ))}
      </>
    );
  }

  return (
    <Box borderRadius="12px" padding="24px" bg="#FFFFFF" border="1px solid #E2E8F0">
      <Text fontSize="16px" lineHeight="18px" fontWeight="700" color="#1B1A1A">
        {header}
      </Text>
      {getMainContent()}
    </Box>
  );
};

export interface SystemPostDetails {
  post: SystemPost;
  onDeletePost: (post: SystemPost) => void;
}

function SystemPostDetails(props: Readonly<SystemPostDetails>) {
  const {post, onDeletePost} = props;

  const {isOpen, onOpen, onClose} = useDisclosure();
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    mdxEditorRef.current?.setMarkdown(post.text.slice(0, 40) + '...');
  }, [post.text]);

  const deleteSystemPostMutation = useMutation({
    mutationFn: async () => {
      await AxiosInstance.delete(`/admin/communities/posts/${post.id}`);
      onDeletePost(post);
    },
    onSuccess: () => {
      window.toast({
        description: `${post.isDraft ? 'Draft' : 'Scheduled'} Post deleted successfully`,
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return (
    <HStack key={post.id} justifyContent="space-between" mt={6}>
      <DeleteConfirmPopup
        onDelete={deleteSystemPostMutation.mutate}
        onCancel={onClose}
        cancelRef={null}
        isOpen={isOpen}
        loading={deleteSystemPostMutation.isPending}
        headerText={`Delete ${post.isDraft ? 'Draft' : 'Scheduled'} Post`}
      />
      <Box>
        {post.metadata?.postTitle && post.metadata?.postTitle !== '' && (
          <Text as="h2" ml={3} fontWeight="600">
            {post.metadata?.postTitle.slice(0, 40)}
          </Text>
        )}
        <MDXEditor
          ref={mdxEditorRef}
          markdown={replaceMentionValues(post.text, ({name}) => {
            return `<span style="color: #B57243">@${name}</span>`;
          })}
          readOnly
          plugins={[linkPlugin(), linkDialogPlugin(), headingsPlugin()]}
        />

        {post.scheduledAt && (
          <Text ml={3} fontSize="16px" lineHeight="24px" fontWeight="400" color="#8E959E">
            {format(
              new Date(post.scheduledAt).toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles',
              }),
              'MM/dd/yyyy, h.mmaaaa',
            )}{' '}
            PST
          </Text>
        )}
      </Box>
      <Box>
        <ChakraLink as={ReactRouterLink} to={`/communities/posts/${post.id}`}>
          <IconButton
            aria-label="Edit scheduled post"
            icon={<FaPencilAlt />}
            variant="outline"
          />
        </ChakraLink>

        <IconButton
          ml={2}
          aria-label="Delete scheduled post"
          icon={<DeleteIcon />}
          colorScheme="red"
          variant="outline"
          onClick={onOpen}
        />
      </Box>
    </HStack>
  );
}
