/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export const mentionRegEx = /((@)\[([^[]*)]\(([^(^)]*)\))/gi;

export interface MentionData {
  original: string;
  trigger: string;
  name: string;
  id: string;
}

/**
 * Replace all mention values in value to some specified format
 *
 * @param value - value that is generated by MentionInput component
 * @param replacer - function that takes mention object as parameter and returns string
 */
export const replaceMentionValues = (
  value: string,
  replacer: (mention: MentionData) => string,
) =>
  value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
    replacer({
      original,
      trigger,
      name,
      id,
    }),
  );
