// Playlist.tsx
import React, {useRef} from 'react';
import {
  Box,
  Text,
  Button,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Card,
  CardBody,
  Heading,
  Flex,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {v4 as uuidv4} from 'uuid';
import {Video} from '../../types/Video.ts';
import {Week} from '../../types/Week.ts';
import WeekComponent from './WeekComponent.tsx';
import MDXEditorComponent from '../MDXEditorComponent/MDXEditorComponent.tsx';
import {MDXEditorMethods} from '@mdxeditor/editor';
import {Step} from '../../types/Step.ts';
import {Day} from '../../types/Day.ts';
import ThumbnailUploadModal from '../ThumbnailUploadModal/ThumbnailUploadModal.tsx';

interface PlaylistProps {
  weeks: Week[];
  setWeeks: React.Dispatch<React.SetStateAction<Week[]>>;
  stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
  handleGoToVideo: (videoId: number) => void;
}
type AlertInfo = {type: 'step'; id: string} | {type: string; id: string | number};
const Playlist: React.FC<PlaylistProps> = ({weeks, setWeeks, stage, handleGoToVideo}) => {
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [alertInfo, setAlertInfo] = React.useState<AlertInfo>({type: '', id: ''});
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [isTextStepModalOpen, setIsTextStepModalOpen] = React.useState(false);
  const [textStepTitle, setTextStepTitle] = React.useState('');
  const [textStepThumbnails, setTextStepThumbnails] = React.useState<Record<
    string,
    string
  > | null>({});

  const [textStepContent, setTextStepContent] = React.useState('');
  const {
    isOpen: isOpenTextStepThumbnailModal,
    onClose: onCloseTextStepThumbnailModal,
    onOpen: onOpenTextStepThumbnailModal,
  } = useDisclosure({
    id: 'textStepThumbnailModal',
  });

  const [newTextStepUUID, setNewTextStepUUID] = React.useState<string>(uuidv4());

  const [currentDayUuid, setCurrentDayUuid] = React.useState<string | null>(null);
  const [editedStepUuid, setEditedStepUuid] = React.useState<string | null>(null);

  const markdownRef = useRef<MDXEditorMethods>(null);

  const onCloseAlert = () => setIsAlertOpen(false);
  const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: DropResult) => {
    const {destination, source, type} = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'day') {
      const sourceWeekIndex = weeks.findIndex(week => week.uuid === source.droppableId);
      const destinationWeekIndex = weeks.findIndex(
        week => week.uuid === destination.droppableId,
      );

      const sourceWeek = weeks[sourceWeekIndex];
      const destinationWeek = weeks[destinationWeekIndex];

      if (sourceWeek === destinationWeek) {
        const newDays = reorder(sourceWeek.days, source.index, destination.index);
        const newWeek = {...sourceWeek, days: newDays};
        const newWeeks = [
          ...weeks.slice(0, sourceWeekIndex),
          newWeek,
          ...weeks.slice(sourceWeekIndex + 1),
        ];
        setWeeks(newWeeks);
      } else {
        const sourceDay = sourceWeek.days[source.index];
        const newSourceDays = Array.from(sourceWeek.days);
        newSourceDays.splice(source.index, 1);
        const newSourceWeek = {...sourceWeek, days: newSourceDays};

        const newDestinationDays = Array.from(destinationWeek.days);
        newDestinationDays.splice(destination.index, 0, sourceDay);
        const newDestinationWeek = {...destinationWeek, days: newDestinationDays};

        const newWeeks = [
          ...weeks.slice(0, sourceWeekIndex),
          newSourceWeek,
          ...weeks.slice(sourceWeekIndex + 1, destinationWeekIndex),
          newDestinationWeek,
          ...weeks.slice(destinationWeekIndex + 1),
        ];
        setWeeks(newWeeks);
      }
    } else if (type === 'step') {
      const sourceWeekIndex = weeks.findIndex(week =>
        week.days.some(day => day.uuid === source.droppableId),
      );
      const destinationWeekIndex = weeks.findIndex(week =>
        week.days.some(day => day.uuid === destination.droppableId),
      );

      const sourceWeek = weeks[sourceWeekIndex];
      const destinationWeek = weeks[destinationWeekIndex];

      const sourceDayIndex = sourceWeek.days.findIndex(
        day => day.uuid === source.droppableId,
      );
      const destinationDayIndex = destinationWeek.days.findIndex(
        day => day.uuid === destination.droppableId,
      );

      const sourceDay = sourceWeek.days[sourceDayIndex];
      const destinationDay = destinationWeek.days[destinationDayIndex];

      if (sourceDay === destinationDay) {
        const newSteps = reorder(sourceDay.steps, source.index, destination.index);
        const newDay = {...sourceDay, steps: newSteps};
        const newWeeks = weeks.map(week =>
          week.uuid === sourceWeek.uuid
            ? {
                ...week,
                days: week.days.map(day => (day.uuid === newDay.uuid ? newDay : day)),
              }
            : week,
        );
        setWeeks(newWeeks);
      }
    }
  };
  const handleAddWeek = () => {
    const newWeek: Week = {
      id: null,
      uuid: uuidv4(),
      title: `Week ${weeks.length + 1}`,
      expanded: true,
      days: [],
    };

    setWeeks(prevState => [...prevState, newWeek]);
  };

  const toggleWeekExpansion = (weekUuid: string) => {
    const newData = {
      ...weeks,
      weeks: weeks.map(week =>
        week.uuid === weekUuid ? {...week, expanded: !week.expanded} : week,
      ),
    };
    setWeeks(newData.weeks);
  };

  const openTextStepModal = (dayUuid: string) => {
    setCurrentDayUuid(dayUuid);
    setTextStepTitle('');
    setTextStepContent('');
    setIsTextStepModalOpen(true);
  };

  const closeTextStepModal = () => {
    setIsTextStepModalOpen(false);
    setTextStepTitle('');
    setTextStepContent('');
    setCurrentDayUuid(null);
    setEditedStepUuid(null);
    setTextStepThumbnails({});
  };

  const handleAddDay = (weekUuid: string) => {
    const newData = weeks.map(week =>
      week.uuid === weekUuid
        ? {
            ...week,
            expanded: true,
            days: [
              ...week.days,
              {
                id: null,
                uuid: uuidv4(),
                title: `Day ${week.days.length + 1}`,
                steps: [],
              },
            ],
          }
        : week,
    );
    setWeeks(newData);
  };

  const createVideoStep = (video: Video): Step => ({
    id: null,
    uuid: uuidv4(),
    title: video.title ?? 'No title',
    duration: video.muxAsset.duration,
    type: 'video',
    videoId: video.id,
    videoThumbnail: video.thumbnails?.thumbnail ?? video.thumbnailUrl ?? undefined,
    published: video.published,
    textStepThumbnails: null,
    video: video,
    textStepThumbnailUrl: null,
  });

  const addStepsToDay = (day: Day, videos: Video[]): Day => ({
    ...day,
    steps: [...day.steps, ...videos.map(createVideoStep)],
  });

  const updateDaysInWeek = (
    week: Week,
    dayUuid: string | number,
    videos: Video[],
  ): Week => ({
    ...week,
    days: week.days.map(day => (day.uuid === dayUuid ? addStepsToDay(day, videos) : day)),
  });

  const handleAddVideoSteps = (dayUuid: string, videos: Video[]) => {
    const newData = weeks.map(week => updateDaysInWeek(week, dayUuid, videos));
    setWeeks(newData);
  };

  const handleAddTextStep = (dayUuid: string) => {
    openTextStepModal(dayUuid);
  };

  const createSurveyStep = (): Step => ({
    id: null,
    uuid: uuidv4(),
    title: 'Program Feedback Survey',
    duration: '00:00',
    type: 'survey' as const,
    videoId: null,
    videoThumbnail: undefined,
    textStepThumbnails: null,
    video: null,
    textStepThumbnailUrl: null,
  });

  const addSurveyStepToDay = (day: Day, dayUuid: string, newSurveyStep: Step): Day => {
    if (day.uuid !== dayUuid) return day;
    return {...day, steps: [...day.steps, newSurveyStep]};
  };

  const updateDaysInWeekWithSurvey = (
    week: Week,
    dayUuid: string,
    newSurveyStep: Step,
  ): Week => ({
    ...week,
    days: week.days.map(day => addSurveyStepToDay(day, dayUuid, newSurveyStep)),
  });

  const updateWeeksWithSurvey = (
    weeks: Week[],
    dayUuid: string,
    newSurveyStep: Step,
  ): Week[] => weeks.map(week => updateDaysInWeekWithSurvey(week, dayUuid, newSurveyStep));

  const handleAddSurveyStep = (dayUuid: string) => {
    const newSurveyStep = createSurveyStep();
    setWeeks(prevWeeks => updateWeeksWithSurvey(prevWeeks, dayUuid, newSurveyStep));
  };

  const confirmDelete = (type: string, id: string | number) => {
    setAlertInfo({type, id});
    setIsAlertOpen(true);
  };

  const handleDeleteWeek = (weekUuid: string) => {
    confirmDelete('week', weekUuid);
  };

  const handleDeleteDay = (dayUuid: string) => {
    confirmDelete('day', dayUuid);
  };

  const handleDeleteStep = (dayUuid: string, stepUuid: string) => {
    confirmDelete('step', `${dayUuid}:${stepUuid}`);
  };

  const deleteWeek = (weekUuid: string) => {
    setWeeks(prevWeeks => prevWeeks.filter(week => week.uuid !== weekUuid));
  };

  const deleteDay = (dayUuid: string) => {
    setWeeks(prevWeeks =>
      prevWeeks.map(week => ({
        ...week,
        days: week.days.filter(day => day.uuid !== dayUuid),
      })),
    );
  };

  const deleteStep = (dayId: string, stepId: string) => {
    setWeeks(prevWeeks =>
      prevWeeks.map(week => ({
        ...week,
        days: week.days.map(day =>
          day.uuid === dayId
            ? {
                ...day,
                steps: day.steps.filter(step => step.uuid !== stepId),
              }
            : day,
        ),
      })),
    );
  };

  const deleteItem = () => {
    const {type, id} = alertInfo;

    if (type === 'week') {
      deleteWeek(id as string);
      onCloseAlert();
      return;
    }

    if (type === 'day') {
      deleteDay(id as string);
      onCloseAlert();
      return;
    }

    if (type === 'step' && typeof id === 'string') {
      const [dayId, stepId] = id.split(':');
      deleteStep(dayId, stepId);
      onCloseAlert();
    }
  };
  const handleUpdateWeekTitle = (weekUuid: string, title: string) => {
    const newWeeks = weeks.map(week => (week.uuid === weekUuid ? {...week, title} : week));
    setWeeks(newWeeks);
  };

  const handleUpdateDayTitle = (dayUuid: string, title: string) => {
    const newWeeks = weeks.map(week => ({
      ...week,
      days: week.days.map(day => (day.uuid === dayUuid ? {...day, title} : day)),
    }));
    setWeeks(newWeeks);
  };

  const saveTextStep = () => {
    if (currentDayUuid) {
      const newData = weeks.map(week => ({
        ...week,
        days: week.days.map(day =>
          day.uuid === currentDayUuid
            ? {
                ...day,
                steps: [
                  ...day.steps,
                  {
                    id: null,
                    uuid: newTextStepUUID,
                    textStepThumbnails: textStepThumbnails,
                    textStepThumbnailUrl: null,
                    title: textStepTitle,
                    duration: '00:00',
                    type: 'text' as const,
                    content: textStepContent,
                    videoId: null,
                    videoThumbnail: undefined,
                    video: null,
                  },
                ],
              }
            : day,
        ),
      }));
      setWeeks(newData);
      setNewTextStepUUID(uuidv4());
      closeTextStepModal();
    }
  };

  const updateTextStep = () => {
    if (currentDayUuid && editedStepUuid) {
      const newData = weeks.map(week => ({
        ...week,
        days: week.days.map(day =>
          day.uuid === currentDayUuid
            ? {
                ...day,
                steps: day.steps.map(step =>
                  step.uuid === editedStepUuid
                    ? {
                        ...step,
                        title: textStepTitle,
                        content: textStepContent,
                        textStepThumbnails: textStepThumbnails,
                      }
                    : step,
                ),
              }
            : day,
        ),
      }));
      setWeeks(newData);
      closeTextStepModal();
    }
  };

  const updateVideoStepTitle = (dayUuid: string, stepUuid: string, title: string) => {
    const newData = weeks.map(week => ({
      ...week,
      days: week.days.map(day =>
        day.uuid === dayUuid
          ? {
              ...day,
              steps: day.steps.map(step =>
                step.uuid === stepUuid ? {...step, title} : step,
              ),
            }
          : day,
      ),
    }));
    setWeeks(newData);
  };

  const handleEditStep = (dayUuid: string, stepUuid: string, step: Step) => {
    setCurrentDayUuid(dayUuid);
    setEditedStepUuid(stepUuid);
    setTextStepTitle(step.title ?? '');
    setTextStepContent(step.content ?? '');
    setTextStepThumbnails(step.textStepThumbnails);
    setIsTextStepModalOpen(true);
  };

  return (
    <>
      <ThumbnailUploadModal
        isOpen={isOpenTextStepThumbnailModal}
        onClose={onCloseTextStepThumbnailModal}
        setThumbnailUrl={items => setTextStepThumbnails(items)}
        entityId={editedStepUuid || newTextStepUUID}
        sizes={[{width: 400, height: 400, name: 'thumbnail'}]}
        aspect={1}
      />
      <Modal isOpen={isTextStepModalOpen} onClose={closeTextStepModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Text Step</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Title"
              value={textStepTitle}
              onChange={e => setTextStepTitle(e.target.value)}
              mb={4}
            />
            <Card mb={5}>
              <CardBody p={10}>
                <Heading size="sm" pb={3}>
                  Thumbnail
                </Heading>
                <Flex justifyContent="center" alignItems="center">
                  {textStepThumbnails ? (
                    <Image src={textStepThumbnails.thumbnail} />
                  ) : (
                    <Box h={32}></Box>
                  )}
                  <Button
                    onClick={() => onOpenTextStepThumbnailModal()}
                    position="absolute"
                    backgroundColor="#80808030">
                    {textStepThumbnails ? 'Change thumbnail' : 'Upload thumbnail'}
                  </Button>
                </Flex>
              </CardBody>
            </Card>
            <Box border="1px solid" borderColor="gray.200" borderRadius="md" mb={10}>
              <MDXEditorComponent
                description={textStepContent}
                setDescription={setTextStepContent}
                markdownRef={markdownRef}
                placeholder="Program description"
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeTextStepModal} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={editedStepUuid ? updateTextStep : saveTextStep}>
              {editedStepUuid ? 'Update' : 'Save'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box pt={4} pb={4}>
        <Text color="#1B1A1A" fontSize="16px" fontWeight="700" mb={4}>
          Playlist
        </Text>
        <VStack spacing={4} align="stretch">
          {weeks.map((week, index) => (
            <DragDropContext key={week.uuid} onDragEnd={onDragEnd}>
              <WeekComponent
                key={week.uuid}
                week={week}
                index={index}
                toggleWeekExpansion={toggleWeekExpansion}
                handleAddDay={handleAddDay}
                handleAddVideoSteps={handleAddVideoSteps}
                handleAddTextStep={handleAddTextStep}
                handleAddSurveyStep={handleAddSurveyStep}
                handleDeleteWeek={handleDeleteWeek}
                handleDeleteDay={handleDeleteDay}
                handleDeleteStep={handleDeleteStep}
                handleUpdateWeekTitle={handleUpdateWeekTitle}
                handleUpdateDayTitle={handleUpdateDayTitle}
                handleEditStep={handleEditStep}
                stage={stage}
                handleGoToVideo={handleGoToVideo}
                handleVideoStepTitle={updateVideoStepTitle}
              />
            </DragDropContext>
          ))}
        </VStack>
        <Button size="sm" leftIcon={<AddIcon />} mt={4} onClick={handleAddWeek}>
          Add week
        </Button>
      </Box>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete {alertInfo.type}
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can not undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onCloseAlert} ref={cancelRef}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteItem} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Playlist;
