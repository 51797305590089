/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {HStack, Box, Spinner, Flex, Text, useDisclosure, Button} from '@chakra-ui/react';
import CommentItem from './CommentItem';
import {useLayoutEffect, useRef, useState} from 'react';
import NotificationSendCommentInput from './NotificationSendCommentInput';
import {useMutation} from '@tanstack/react-query';
import {CommentRepository, ReactionRepository} from '@amityco/ts-sdk';
import queryClient from '../../api/queryClient';
import DeleteConfirmPopup from '../Common/DeleteConfirmPopup';
import {AxiosInstance} from '../../api/_AxiosInstance';
import {AmitySDKcommentType} from '../../types/AmityGetCommentByIdResponse';
import {useGlobalStore} from '../../store/globalStore.ts';
import {replaceMentionValues} from '../../helpers/mentionHelpers.ts';

let nextPageFn: (() => void) | undefined;

export interface SingleCommentThreadProps {
  commentThreadId?: string;
  defaultIsReplying?: boolean;
  highlightCommentId?: string;
  parentComment: AmitySDKcommentType;
  updateParentComment: (comment: AmitySDKcommentType) => void;
  handleCommentDelete?: () => void;
  onAddReply?: (comment: AmitySDKcommentType) => void;
  onDeleteReply?: (commentId: string) => void;
}

export default function SingleCommentThread(props: SingleCommentThreadProps) {
  const {
    defaultIsReplying = false,
    parentComment,
    highlightCommentId,
    handleCommentDelete,
    updateParentComment,
    onAddReply,
    onDeleteReply,
  } = props;
  const [comment, setComment] = useState('');
  const [isReplying, setIsReplying] = useState(defaultIsReplying);
  const cancelRef = useRef(null);
  const [commentIdToDelete, setCommentIdToDelete] = useState('');

  const {isOpen, onOpen, onClose} = useDisclosure();

  const [loadingAllComments, setLoadingAllComments] = useState(false);
  const [allComments, setAllComments] = useState<AmitySDKcommentType[]>([]);
  const [commentsError, setCommentsError] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean | undefined>(false);

  const [editingCommentId, setEditingCommentId] = useState<string>('');
  const [editText, setEditText] = useState('');
  const globalState = useGlobalStore(state => state);

  const handleEditClick = (commentId: string, currentText: string) => {
    setEditingCommentId(commentId);
    setEditText(currentText);
  };

  const handleCancelEdit = () => {
    setEditingCommentId('');
    setEditText('');
  };

  const handleSaveEdit = async (commentId: string) => {
    if (!editText.trim()) return;

    await updateCommentMutation.mutateAsync({
      commentId: commentId,
      text: editText.trim(),
    });

    setEditingCommentId('');
    setEditText('');

    updateParentComment({
      ...parentComment,
      data: {...parentComment.data, text: editText.trim()},
    });
  };

  const handleEditReply = async (commentId: string) => {
    if (!editText.trim()) return;

    await updateCommentMutation.mutateAsync({
      commentId: commentId,
      text: editText.trim(),
    });

    setEditingCommentId('');
    setEditText('');

    setAllComments(prev =>
      prev.map(comment =>
        comment._id === commentId
          ? {
              ...comment,
              data: {
                ...parentComment.data,
                text: editText.trim(),
              },
            }
          : comment,
      ),
    );
  };

  useLayoutEffect(() => {
    if (!isReplying || !parentComment?.childrenNumber) return;

    const commentsPostParams: Amity.CommentLiveCollection = {
      referenceType: 'post',
      referenceId: parentComment.referenceId,
      dataTypes: {
        values: ['text'],
        matchType: 'exact',
      },
      limit: 10,
      parentId: parentComment._id,
    };

    const unsubscribe = CommentRepository.getComments(
      commentsPostParams,
      ({data: comments, onNextPage, hasNextPage, loading, error}) => {
        if (error) {
          setLoadingAllComments(false);
          setCommentsError(error);
          return;
        }
        if (loading) {
          setLoadingAllComments(true);
          return;
        }
        if (comments) {
          setLoadingAllComments(false);
          setAllComments(comments as unknown as AmitySDKcommentType[]);
        }
        setHasMore(hasNextPage);
        nextPageFn = onNextPage;
      },
    );

    unsubscribe();
  }, [isReplying]);

  const createReplyCommentMutation = useMutation({
    mutationFn: createReplyComment,
    onError() {
      window.toast({
        description: 'Cannot create reply. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: async ({commentId, text}: {commentId: string; text: string}) => {
      await CommentRepository.updateComment(commentId, {
        data: {text},
      });
    },
    onSuccess: () => {
      window.toast({
        description: 'Comment updated successfully.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });
    },
    onError: () => {
      window.toast({
        description: 'Cannot update comment. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  async function createReplyComment() {
    const mentionees: string[] = [];
    replaceMentionValues(comment, ({id}) => {
      mentionees.push(id);
      return `@${id}`;
    });

    const createdCommentResponse = await CommentRepository.createComment({
      referenceType: 'post',
      referenceId: parentComment.referenceId,
      parentId: parentComment._id,
      data: {
        text: comment,
      },
      metadata: {
        admin: true,
      },
      mentionees:
        mentionees.length > 0
          ? ([{type: 'user', userIds: mentionees}] as Amity.MentionType['user'][])
          : [],
    });

    setAllComments(prev => [
      ...prev,
      createdCommentResponse.data as unknown as AmitySDKcommentType,
    ]);
    updateParentComment({
      ...parentComment,
      childrenNumber: parentComment.childrenNumber + 1,
    });
    setComment('');
    onAddReply?.(createdCommentResponse.data as unknown as AmitySDKcommentType);

    window.toast({
      description: 'Reply created successfully.',
      status: 'success',
      position: 'top-right',
      isClosable: true,
      duration: 5000,
    });
  }

  const deleteMainThreadMutation = useMutation({
    mutationFn: async () => {
      await AxiosInstance.delete(`/notifications/comments`, {
        params: {
          amityParentCommentId: parentComment._id,
          amityCommentId: parentComment._id,
        },
      });
      setIsReplying(false);
      handleCommentDelete?.();

      queryClient.refetchQueries({
        queryKey: ['comments'],
      });

      window.toast({
        description: 'Comment deleted successfully.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });

      onClose();
      setCommentIdToDelete('');
    },
    onError(e) {
      window.toast({
        description: 'Cannot delete reply. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const deleteReplyMutation = useMutation({
    mutationFn: async (commentId: string) => {
      await AxiosInstance.delete(`/notifications/comments`, {
        params: {
          amityCommentId: commentId,
        },
      });

      setAllComments(allComments.filter(comment => comment._id !== commentId));
      updateParentComment({
        ...parentComment,
        childrenNumber: parentComment.childrenNumber - 1,
      });
      queryClient.refetchQueries({
        queryKey: ['comments'],
      });
      onDeleteReply?.(commentId);

      window.toast({
        description: 'Reply deleted successfully.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 5000,
      });

      onClose();
      setCommentIdToDelete('');
    },
    onError(e) {
      window.toast({
        description: 'Cannot delete reply. Please try again.',
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  const handleReplyClick = () => {
    if (!defaultIsReplying) {
      setIsReplying(!isReplying);
    }
  };

  // TODO: trycatch
  async function handleLikeClick(
    amityComment: AmitySDKcommentType,
    isParentComment = false,
  ) {
    function removeCommentReaction(data: AmitySDKcommentType) {
      return {
        ...data,
        reactions: {
          ...data.reactions,
          like: data.reactions.like - 1,
        },
        myReactions: (data.myReactions ?? []).filter(reaction => reaction !== 'like'),
      };
    }
    function addCommentReaction(data: AmitySDKcommentType) {
      return {
        ...data,
        reactions: {
          ...data.reactions,
          like: data.reactions.like ? data.reactions.like + 1 : 1,
        },
        myReactions: [...(data.myReactions ?? []), 'like'],
      };
    }

    if (amityComment.myReactions?.includes('like')) {
      await ReactionRepository.removeReaction('comment', amityComment._id, 'like');
      if (!isParentComment) {
        updateComment(removeCommentReaction(amityComment));
      } else {
        updateParentComment(removeCommentReaction(parentComment));
      }
    } else {
      await ReactionRepository.addReaction('comment', amityComment._id, 'like');
      if (!isParentComment) {
        updateComment(addCommentReaction(amityComment));
      } else {
        updateParentComment(addCommentReaction(parentComment));
      }
    }
  }

  function updateComment(amityComment: AmitySDKcommentType) {
    setAllComments(prev => {
      const index = prev.findIndex(comment => comment._id === amityComment._id);
      if (index === -1) return prev;
      prev[index] = amityComment;
      return [...prev];
    });
  }

  return (
    <Box>
      <DeleteConfirmPopup
        onDelete={() => {
          if (parentComment._id === commentIdToDelete) {
            deleteMainThreadMutation.mutate();
          } else {
            deleteReplyMutation.mutate(commentIdToDelete);
          }
        }}
        onCancel={() => {
          setCommentIdToDelete('');
          onClose();
        }}
        cancelRef={cancelRef}
        isOpen={isOpen}
        loading={deleteReplyMutation.isPending || deleteMainThreadMutation.isPending}
        headerText="Delete reply"
      />
      <CommentItem
        comment={parentComment.data.text}
        avatarUrl={(parentComment.creator as any).avatarCustomUrl}
        timeAgo={parentComment.createdAt}
        updatedAt={parentComment.updatedAt}
        username={parentComment.creator.displayName}
        showReply={!defaultIsReplying}
        numberOfReplies={parentComment.childrenNumber}
        onReplyClick={handleReplyClick}
        isHighlighted={parentComment._id === highlightCommentId}
        canDelete
        canEdit={parseInt(parentComment.userId) === globalState?.authUser?.id}
        isSavingEdit={updateCommentMutation.isPending}
        isEditing={editingCommentId === parentComment._id}
        editText={editingCommentId === parentComment._id ? editText : ''}
        onEditTextChange={e => setEditText(e.target.value)}
        onEditClick={() => handleEditClick(parentComment._id, parentComment.data.text)}
        onCancelEdit={handleCancelEdit}
        onSaveEdit={() => handleSaveEdit(parentComment._id)}
        onDeleteclick={() => {
          onOpen();
          setCommentIdToDelete(parentComment._id);
        }}
        showLike
        isLiked={parentComment.myReactions?.includes('like')}
        likeCount={parentComment.reactions?.like}
        onLikeClick={() => handleLikeClick(parentComment, true)}
        isCoach={parentComment.metadata?.admin}>
        {isReplying && loadingAllComments && (
          <Flex justifyContent="center" alignItems="center">
            <HStack>
              <Text>Loading replies...</Text>
              <Spinner size="sm" color="blue.500" />
            </HStack>
          </Flex>
        )}

        {isReplying &&
          allComments?.length > 0 &&
          allComments?.map(comment => (
            <CommentItem
              key={comment._id}
              comment={comment.data.text}
              avatarUrl={(comment.creator as any).avatarCustomUrl}
              timeAgo={comment.createdAt}
              updatedAt={comment.updatedAt}
              username={comment.creator.displayName}
              componentProps={{
                w: '100%',
                padding: 0,
                marginTop: 2,
              }}
              isHighlighted={comment._id === highlightCommentId}
              canDelete
              canEdit={parseInt(comment.userId) === globalState?.authUser?.id}
              isSavingEdit={updateCommentMutation.isPending}
              isEditing={editingCommentId === comment._id}
              editText={editingCommentId === comment._id ? editText : ''}
              onEditTextChange={e => setEditText(e.target.value)}
              onEditClick={() => handleEditClick(comment._id, comment.data.text)}
              onCancelEdit={handleCancelEdit}
              onSaveEdit={() => handleEditReply(comment._id)}
              isLoadingDelete={deleteReplyMutation.isPending}
              onDeleteclick={() => {
                onOpen();
                setCommentIdToDelete(comment._id);
              }}
              showLike
              isLiked={comment.myReactions?.includes('like')}
              likeCount={comment.reactions?.like}
              onLikeClick={() => handleLikeClick(comment)}
              isCoach={comment.metadata?.admin}
            />
          ))}

        {hasMore && (
          <Flex justifyContent="center" alignItems="center">
            <Button onClick={nextPageFn} isLoading={loadingAllComments}>
              Load more
            </Button>
          </Flex>
        )}
        {isReplying && !loadingAllComments && (
          <HStack w="full" marginTop={2}>
            <NotificationSendCommentInput
              comment={comment}
              setComment={setComment}
              loadingCreatingReply={createReplyCommentMutation.isPending}
              handlePostClick={createReplyCommentMutation.mutate}
            />
          </HStack>
        )}
      </CommentItem>
    </Box>
  );
}
