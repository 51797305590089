import {AtSignIcon} from '@chakra-ui/icons';
import {IconButton} from '@chakra-ui/react';
import {TooltipWrap} from '@mdxeditor/editor';
import AddMentionModal, {ISearchItem} from './AddMentionModal';
import {useState} from 'react';

export interface AddMentionToolbarButtonProps {
  onSelect: (option: ISearchItem) => void;
}

function AddMentionToolbarButton(props: AddMentionToolbarButtonProps) {
  const {onSelect} = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <TooltipWrap title="Add mention">
        <IconButton
          icon={<AtSignIcon />}
          size="sm"
          aria-label="mention"
          onClick={() => setIsModalOpen(true)}
        />
      </TooltipWrap>

      <AddMentionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={onSelect}
      />
    </>
  );
}

export default AddMentionToolbarButton;
