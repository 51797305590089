import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  Circle,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {ColumnDef, SortingState, createColumnHelper} from '@tanstack/react-table';
import React, {useCallback, useMemo, useState} from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import ResultsTable from '../../components/ResultsTable/ResultsTable.tsx';
import {BiBookmark} from 'react-icons/bi';
import {LuUser2} from 'react-icons/lu';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {useQuery} from '@tanstack/react-query';
import Paginator from '../../components/Paginator/Paginator.tsx';
import {
  CommentPreviewType,
  Notification,
  NotificationsResponse,
  NotificationType,
} from '../../types/Notifications.ts';
import {format} from 'date-fns';
import ResultsTableDeleteMenuColumn from '../../components/ResultsTable/ResultsTableDeleteMenuColumn.tsx';
import queryClient from '../../api/queryClient.ts';
import NotificationsModal from '../../components/NotificationsModal/NotificationsModal.tsx';

const columnHelper = createColumnHelper<Notification>();

const commmentTypeMap: Record<string, string> = {
  step_comment: 'Step Comment',
  community_comment: 'Community Comment',
  community_post: 'Community Post',
};

const userStageMap: Record<string, string> = {
  trying_to_conceive: 'Trying to conceive',
  pregnant: 'Pregnant',
  postpartum: 'Postpartum',
};

interface GetColumnsOptions {
  onDelete: (comment: Notification) => Promise<any>;
  toggleReadStatus: (comment: Notification) => Promise<any>;
}

const getColumns = (options: GetColumnsOptions) => [
  // columnHelper.display({
  //   id: 'id',
  //   header: ({table}) => (
  //     <ResultsTableCheckboxColumn
  //       isChecked={table.getIsAllRowsSelected()}
  //       onChange={table.getToggleAllRowsSelectedHandler()}
  //       text="ID"
  //     />
  //   ),
  //   cell: ({row}) => (
  //     <ResultsTableCheckboxColumn
  //       isChecked={row.getIsSelected()}
  //       onChange={row.getToggleSelectedHandler()}
  //       text={row.original.id.toString()}
  //     />
  //   ),
  // }),
  columnHelper.display({
    header: 'User',
    cell: ({cell}) => (
      <Flex alignItems="center">
        <Circle size="32px" bg="#F4DCCB">
          <LuUser2 />
        </Circle>

        <Text color="#333333" fontWeight="700" ml={2}>
          {cell.row.original.user?.firstName ?? ''} {cell.row.original.user?.lastName ?? ''}
        </Text>
      </Flex>
    ),
  }),
  columnHelper.display({
    header: 'Notification Type',
    cell: ({cell}) => (
      <Text color="#5F6873" fontWeight="400" textAlign="center">
        {commmentTypeMap[cell.row.original.notificationType]}
      </Text>
    ),
  }),
  columnHelper.display({
    header: 'Program / Step',
    cell: ({cell}) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      cell.row.original.notificationType === 'step_comment' ? (
        <Text color="#5F6873" fontWeight="400">
          {cell.row.original.program?.title ?? ''}・
          {cell.row.original.step?.textStepTitle ?? ''}
        </Text>
      ) : null,
  }),
  columnHelper.display({
    header: 'Stage',
    cell: ({cell}) => (
      <Text color="#5F6873">
        {cell.row.original.userStage ? userStageMap[cell.row.original.userStage] ?? '' : ''}
      </Text>
    ),
  }),
  columnHelper.display({
    header: 'Comment Type',
    cell: ({cell}) =>
      cell.row.original.notificationType === NotificationType.STEP_COMMENT ? (
        <Text color="#5F6873" fontWeight="400">
          {cell.row.original.commentPreviewType === CommentPreviewType.COMMENT
            ? 'Comment'
            : 'Question'}
        </Text>
      ) : null,
  }),
  columnHelper.display({
    header: 'Text',
    cell: ({cell}) => (
      <Text
        color="#5F6873"
        fontWeight="400"
        width="150px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis">
        {cell.row.original.text}
      </Text>
    ),
  }),

  columnHelper.display({
    header: 'Date',
    cell: ({cell}) => {
      return (
        <Text color="#5F6873" fontWeight="400">
          {format(new Date(cell.row.original.date), 'MM/dd/yy')}
        </Text>
      );
    },
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.display({
    id: 'mark as',
    cell: ({cell}) => (
      <Button
        leftIcon={<BiBookmark />}
        onClick={e => {
          e.stopPropagation();
          options.toggleReadStatus(cell.row.original);
        }}
        colorScheme="gray"
        variant="outline"
        textColor={cell.row.original.read ? '#8E959E' : '#1B1A1A'}
        fontWeight={cell.row.original.read ? 'unset' : 600}>
        {cell.row.original.read ? 'Mark as unread' : 'Mark as read'}
      </Button>
    ),
  }),
  columnHelper.display({
    id: 'edit',
    cell: ({cell}) => (
      <ResultsTableDeleteMenuColumn onDelete={() => options.onDelete(cell.row.original)} />
    ),
  }),
];

export default function CommentsAndQuestionsPage() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const limit = 10;
  const [selectedCommentPreview, setSelectedCommentPreview] = useState<Notification>();

  const {isOpen: isModalOpen, onOpen: onModalOpen, onClose} = useDisclosure();

  const [filters, setFilters] = useState({
    currentPage: 1,
    tabIndex: 0,
  });

  function updateFilters(newFilters: Partial<typeof filters>) {
    setFilters({...filters, ...newFilters});
  }

  const getCommentsQuery = useQuery({
    queryKey: ['comments', filters.currentPage, limit, filters.tabIndex],
    queryFn: async ({queryKey}) => {
      const {data} = await AxiosInstance.get<NotificationsResponse>('/notifications', {
        params: {
          page: queryKey[1] as number,
          limit: queryKey[2] as number,
          read: queryKey[3] === 1,
        },
      });
      return data;
    },
  });

  const handleDeleteButtonPressed = useCallback(
    async (comment: Notification) => {
      if (comment.notificationType === NotificationType.COMMUNITY_POST) {
        // delete community post
        await AxiosInstance.delete(`/amity-posts/${comment.amityPostId!}`);

        window.toast({
          description: 'Post deleted successfully',
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
      } else {
        await AxiosInstance.delete<NotificationsResponse>(`/notifications/comments`, {
          params: {
            amityCommentId: comment.amityCommentId,
            amityParentCommentId: comment.amityParentCommentId,
          },
        });
        window.toast({
          description: 'Comment deleted successfully',
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
      }
      getCommentsQuery.refetch();
    },
    [getCommentsQuery],
  );

  const toggleReadStatus = useCallback(
    async (comment: Notification) => {
      let updatedDataLength = limit; // used to check if the data length is at 1 to refetch the data

      // Optimistically update the UI
      function updateReadStatus(oldData: NotificationsResponse) {
        if (!oldData?.data) {
          return oldData;
        }

        if (
          (!comment.read && filters.tabIndex === 0) ||
          (comment.read && filters.tabIndex === 1)
        ) {
          const newData = {
            ...oldData,
            data: oldData.data.filter(c => c.id !== comment.id),
            total: Math.max(0, (oldData.total ?? 0) - 1),
          };

          updatedDataLength = newData.data.length;

          return newData;
        }

        return {
          ...oldData,
          data: oldData.data.map(c => (c.id === comment.id ? {...c, read: !c.read} : c)),
        };
      }

      queryClient.setQueryData(
        ['comments', filters.currentPage, limit, filters.tabIndex],
        (oldData: NotificationsResponse) => updateReadStatus(oldData),
      );

      try {
        await AxiosInstance.patch<NotificationsResponse>(
          `/notifications/${comment.id}/read`,
        );

        if (updatedDataLength === 1) {
          getCommentsQuery.refetch();
          updatedDataLength = limit;
        }
      } catch (error) {
        queryClient.setQueryData(
          ['comments', filters.currentPage, limit, filters.tabIndex],
          (oldData: NotificationsResponse) => updateReadStatus(oldData),
        );

        window.toast({
          description: 'Could not update read status. Please try again.',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 5000,
        });
      }
    },
    [filters.currentPage, limit, filters.tabIndex, getCommentsQuery.data],
  );

  const columns = useMemo(
    () => getColumns({onDelete: handleDeleteButtonPressed, toggleReadStatus}),
    [handleDeleteButtonPressed, toggleReadStatus],
  );

  const onModalClose = () => {
    onClose();
    setSelectedCommentPreview(undefined);
  };

  const onTableRowClick = (row: Notification) => {
    setSelectedCommentPreview(row);
    if (filters.tabIndex === 0) {
      toggleReadStatus(row);
    }
    onModalOpen();
  };

  function handleTabIndexChange(index: number) {
    setFilters({
      currentPage: 1,
      tabIndex: index,
    });
  }

  return (
    <Box height="auto">
      <NotificationsModal
        data={selectedCommentPreview!}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
      <Flex justifyContent="space-between">
        <Heading mb={4}>Notifications</Heading>
      </Flex>
      <Tabs
        w="fit-content"
        mb={8}
        position="relative"
        colorScheme="red"
        index={filters.tabIndex}
        onChange={handleTabIndexChange}>
        <TabList>
          <Tab>Unread</Tab>
          <Tab>Read</Tab>
        </TabList>
      </Tabs>

      <Card>
        <CardBody>
          <ResultsTable
            columns={columns as ColumnDef<any>[]}
            data={getCommentsQuery.data?.data ?? []}
            onSortChange={setSorting}
            sorting={sorting}
            onRowClick={onTableRowClick}
            // rowSelection={rowSelection}
            // onRowSelectionChange={setRowSelection}
          />

          {(getCommentsQuery.data?.lastPage ?? 1) > 1 && (
            <Paginator
              onPageChange={({selected}) => {
                updateFilters({
                  currentPage: selected + 1,
                });
              }}
              pageCount={getCommentsQuery.data?.lastPage ?? 1}
              forcePage={filters.currentPage - 1}
            />
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
