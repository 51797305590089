import {
  Card,
  CardBody,
  Box,
  Heading,
  Input,
  Flex,
  Button,
  Grid,
  GridItem,
  RadioGroup,
  Stack,
  Radio,
  Select,
  useDisclosure,
  Image,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
  FormControl,
  FormLabel,
  Switch,
  IconButton,
} from '@chakra-ui/react';
import PlaylistComponent from '../../components/Playlist/PlaylistComponent.tsx';

import React, {ReactElement, useEffect, useRef, useState} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {MDXEditorMethods} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import useApi from '../../api/hooks/useApi.ts';
import {Week} from '../../types/Week.ts';
import {Step} from '../../types/Step.ts';
import {Day} from '../../types/Day.ts';
import {useParams, useNavigate} from 'react-router-dom';
import EquipmentList, {
  EquipmentOption,
} from '../../components/EquipmentList/EquipmentList.tsx';
import MDXEditorComponent from '../../components/MDXEditorComponent/MDXEditorComponent.tsx';
import {useDeepCompareEffect} from 'react-use';
import {
  ProgramDataResponse,
  WeekDataResponse,
  Step as StepResponse,
  Day as DayResponse,
} from '../../types/ProgramDataResponse.ts';
import ThumbnailUploadModal from '../../components/ThumbnailUploadModal/ThumbnailUploadModal.tsx';
import {useMutation} from '@tanstack/react-query';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {LinkIcon} from '@chakra-ui/icons';
import {EnvConfig} from '../../base/EnvConfig.ts';
import {BiCheck} from 'react-icons/bi';

// remove expanded property from the weeks data since it's not relevant in isChanged property
const removeExpanded = (data: Week[]) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return data.map(({expanded, ...rest}) => rest);
};
const ProgramDetailsPage = (): ReactElement => {
  const navigate = useNavigate();
  const markdownRef = useRef<MDXEditorMethods>(null);
  const {id} = useParams();

  const [isConfirmGoToVideoOpen, setIsConfirmGoToVideoOpen] = useState(false);
  const cancelRefConfirmGoToVideo = useRef<HTMLButtonElement>(null);
  const [navigateToVideoId, setNavigateToVideoId] = useState<number | null>(null);

  const [initialState, setInitialState] = useState<{
    title: string;
    description: string;
    shortDescription: string;
    isMostPopular: boolean;
    isNutrition: boolean;
    premium: boolean;
    published: boolean;
    stage: 'pregnant' | 'postpartum' | 'trying_to_conceive' | null;
    level: string | null;
    duration: string | null;
    weeks: any[];
    thumbnailUrl: string | null;
    programThumbnails: Record<string, string> | null;
    equipmentList: EquipmentOption[];
  }>({
    title: '',
    description: '',
    shortDescription: '',
    isMostPopular: false,
    isNutrition: false,
    premium: false,
    published: false,
    stage: null,
    level: '',
    duration: '',
    weeks: [],
    thumbnailUrl: null,
    programThumbnails: null,
    equipmentList: [],
  });

  const [isChanged, setIsChanged] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [shortDescription, setShortDescription] = React.useState<string>('');
  const [isMostPopular, setIsMostPopular] = React.useState<boolean>(false);
  const [isNutrition, setIsNutrition] = React.useState<boolean>(false);

  const [premium, setPremium] = React.useState<boolean>(false);
  const [published, setPublished] = React.useState<boolean>(false);
  const [stage, setStage] = React.useState<
    'pregnant' | 'postpartum' | 'trying_to_conceive' | null
  >(null);
  const [level, setLevel] = React.useState<string | null>(null);
  const [duration, setDuration] = React.useState<string | null>(null);
  const [weeks, setWeeks] = React.useState<Week[]>([]);

  const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(null);
  const [programThumbnails, setProgramThumbnails] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [equipmentList, setEquipmentList] = useState<EquipmentOption[]>([]);

  const [surveyDetails, setSurveyDetails] = useState<
    {
      id: number;
      week: string;
      day: string;
      step: string;
    }[]
  >([]);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const saveProgramMutation = useMutation({
    mutationFn: saveProgram,
  });

  const {trigger: getProgramDataTrigger, data: programData} = useApi<ProgramDataResponse>({
    url: `/admin/programs/${id}`,
    method: 'GET',
    fetchOnMount: !!id,
  });

  useEffect(() => {
    if (programData) {
      const initialProgramState = {
        title: programData.title,
        description: programData.description,
        shortDescription: programData.shortDescription,
        isMostPopular: programData.isPopular,
        isNutrition: programData.isNutrition,
        premium: programData.premium,
        published: programData.published,
        stage: programData.stage,
        level: programData.level,
        duration: programData.duration,
        weeks: removeExpanded(transformWeekDataForFrontend(programData.weeks)),
        thumbnailUrl: programData.thumbnailUrl,
        programThumbnails: programData.programThumbnails,
        equipmentList: programData.equipmentList
          ? (JSON.parse(programData.equipmentList) as EquipmentOption[])
          : [],
      };

      setTitle(programData.title);
      setDescription(programData.description);
      setShortDescription(programData.shortDescription);
      setIsMostPopular(programData.isPopular);
      setIsNutrition(programData.isNutrition);

      if (programData.description) {
        markdownRef.current?.setMarkdown(programData.description);
      }
      setWeeks(transformWeekDataForFrontend(programData.weeks));
      setPremium(programData.premium);
      setPublished(programData.published);
      setThumbnailUrl(programData.thumbnailUrl);
      setProgramThumbnails(programData.programThumbnails);
      setStage(programData.stage);
      setLevel(programData.level);
      setDuration(programData.duration);
      setEquipmentList(
        programData.equipmentList
          ? (JSON.parse(programData.equipmentList) as EquipmentOption[])
          : [],
      );

      findAndSetSurvey(programData);

      setInitialState(initialProgramState);
    }
  }, [programData]);

  // Compare the current state with the initial state
  useDeepCompareEffect(() => {
    const currentState = {
      title,
      description,
      shortDescription,
      isMostPopular,
      isNutrition,
      premium,
      published,
      stage,
      level,
      duration,
      weeks: removeExpanded(weeks),
      programThumbnails,
      equipmentList,
    };

    setIsChanged(JSON.stringify(initialState) !== JSON.stringify(currentState));
  }, [
    title,
    description,
    shortDescription,
    isMostPopular,
    isNutrition,
    premium,
    published,
    stage,
    level,
    duration,
    weeks,
    programThumbnails,
    equipmentList,
  ]);
  const transformStepsForFrontend = (steps: StepResponse[]) => {
    return steps.map(step => {
      let stepTitle = step.textStepTitle;
      // if the stepTitle is empty it's a video step extract it from the video
      if (!stepTitle && step.type === 'video') {
        stepTitle = step.video?.title;
      }
      return {
        id: step.id,
        uuid: step.uuid,
        title: stepTitle,
        type: step.type,
        video: step.video,
        videoId: step.type === 'video' ? step.video?.id : null,
        videoThumbnail: step.type === 'video' ? step.video?.thumbnailUrl : undefined,
        videoThumbnails: step.video?.thumbnails,
        duration: step.type === 'video' ? step.video?.muxAsset.duration : '0',
        content: step.type === 'text' ? step.content : undefined,
        published: step.type === 'video' ? step.video?.published : undefined,
        textStepThumbnailUrl: step.textStepThumbnailUrl,
        textStepThumbnails: step.textStepThumbnails,
      };
    });
  };

  const transformDaysForFrontend = (days: DayResponse[]) => {
    return days.map(day => {
      return {
        id: day.id,
        uuid: day.uuid,
        title: day.title,
        steps: transformStepsForFrontend(day.steps),
      };
    });
  };

  // transform week data from server to be used in the frontend
  const transformWeekDataForFrontend = (data: WeekDataResponse[]): Week[] => {
    return data.map(week => {
      return {
        id: week.id,
        title: week.title,
        uuid: week.uuid,
        expanded: false,
        days: transformDaysForFrontend(week.days),
      };
    });
  };

  // transform week into a format that can be sent to the backend
  const transformWeekDataForBackend = (data: Week[]) => {
    return data.map((week: Week) => ({
      id: typeof week.id === 'number' ? week.id : undefined,
      uuid: week.uuid,
      title: week.title,
      days: week.days.map((day: Day) => ({
        id: typeof day.id === 'number' ? day.id : undefined,
        uuid: day.uuid,
        title: day.title,
        steps: day.steps.map((step: Step) => ({
          id: typeof step.id === 'number' ? step.id : undefined,
          uuid: step.uuid,
          title: step.title,
          type: step.type,
          videoId: step.videoId,
          textStepTitle: step.title,
          content: step.type === 'text' ? step.content : undefined,
          textStepThumbnailUrl: step.textStepThumbnailUrl,
          textStepThumbnails: step.textStepThumbnails,
        })),
      })),
    }));
  };

  async function saveProgram() {
    if (isChanged) {
      const weeksData = transformWeekDataForBackend(weeks);
      const data = {
        title,
        description,
        shortDescription,
        isPopular: isMostPopular,
        isNutrition,
        weeks: weeksData,
        premium,
        published,
        stage,
        level,
        duration: duration !== '' ? duration : null,
        equipmentList: JSON.stringify(equipmentList),
        thumbnailUrl: thumbnailUrl,
        programThumbnails: programThumbnails,
      };

      if (id) {
        await AxiosInstance.put(`/admin/programs/${id}`, data);
        window.toast({
          description: 'Program updated successfully',
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 3000,
        });
        // get program data to update the week/days/steps id from server
        await getProgramDataTrigger();
        setIsChanged(false);
      } else {
        // save program
        const {data: response} = await AxiosInstance.post<ProgramDataResponse>(
          '/admin/programs',
          data,
        );

        window.toast({
          description: 'Program created successfully',
          status: 'success',
          position: 'top',
          isClosable: true,
          duration: 3000,
        });
        navigate(`/programs/edit/${response.id}`);
        setIsChanged(false);
      }
    }
  }

  const handleGoToVideo = (videoId: number) => {
    if (isChanged) {
      setNavigateToVideoId(videoId);
      setIsConfirmGoToVideoOpen(true);
    } else {
      navigate(`/videos/edit/${videoId}`);
    }
  };

  const confirmNavigation = async (saveChanges: boolean) => {
    if (saveChanges) {
      await saveProgramMutation.mutateAsync();
    }
    setIsConfirmGoToVideoOpen(false);
    if (navigateToVideoId !== null) {
      navigate(`/videos/edit/${navigateToVideoId}`);
    }
  };
  const copyProgramLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${EnvConfig.MOBILE_DEEP_LINK_DOMAIN}://program?id=${id}`,
      );
      window.toast({
        description: 'Link copied to clipboard',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 3000,
      });
      // You can also trigger a UI notification here
    } catch (err) {
      console.error('Failed to copy text to clipboard', err);
    }
  };

  const findAndSetSurvey = (programData: ProgramDataResponse) => {
    const surveys = [];
    for (let wIndex = 0; wIndex < programData.weeks.length; wIndex++) {
      const {days} = programData.weeks[wIndex];

      for (let dIndex = 0; dIndex < days.length; dIndex++) {
        const {steps} = days[dIndex];

        for (let sIndex = 0; sIndex < steps.length; sIndex++) {
          if (steps[sIndex].type === 'survey') {
            surveys.push({
              id: surveys.length + 1,
              week: (wIndex + 1).toString(),
              day: (dIndex + 1).toString(),
              step: (sIndex + 1).toString(),
            });
          }
        }
      }
    }

    setSurveyDetails(surveys);
  };

  const hasSurvey = surveyDetails.length > 0;

  return (
    <>
      <AlertDialog
        isOpen={isConfirmGoToVideoOpen}
        leastDestructiveRef={cancelRefConfirmGoToVideo}
        onClose={() => setIsConfirmGoToVideoOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Unsaved Changes
            </AlertDialogHeader>

            <AlertDialogBody>
              You have unsaved changes. Do you want to save them before leaving?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  ref={cancelRefConfirmGoToVideo}
                  onClick={() => setIsConfirmGoToVideoOpen(false)}
                  size="sm">
                  Cancel
                </Button>
                <Flex gap={3}>
                  <Button
                    colorScheme="red"
                    onClick={() => void confirmNavigation(false)}
                    size="sm">
                    Go to video without saving
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => void confirmNavigation(true)}
                    size="sm">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <ThumbnailUploadModal
        isOpen={isOpen}
        onClose={onClose}
        setThumbnailUrl={setProgramThumbnails}
        entityId={id ?? ''}
        sizes={[{width: 1280, height: 720, name: 'thumbnail'}]}
      />
      <Box minHeight="100%" height="100%">
        <Box>
          <Flex alignItems="center" justifyContent="space-between" maxWidth="container.xl">
            <Heading mb={4}>Create Program</Heading>
            <Box alignItems={'center'}>
              <IconButton
                icon={<LinkIcon />}
                size="sm"
                aria-label="Share"
                onClick={() => copyProgramLinkToClipboard()}
                variant="ghost"
                _hover={{bg: 'transparent'}}
                _active={{bg: 'transparent'}}
                fontSize={16}
                color="black.100"
                mb={10}
                mr={2}
              />
              <Button
                colorScheme="black"
                onClick={() => saveProgramMutation.mutate()}
                mb={10}
                isDisabled={!isChanged || saveProgramMutation.isPending}
                isLoading={saveProgramMutation.isPending}>
                Save
              </Button>
            </Box>
          </Flex>
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(5, 1fr)"
            gap={5}
            maxWidth="container.xl">
            <GridItem colSpan={3}>
              <Card mb={5}>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    About program
                  </Heading>
                  <Input
                    placeholder="Program title"
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                    mb={5}
                  />
                  <Box border="1px solid" borderColor="gray.200" borderRadius="md" mb={5}>
                    <MDXEditorComponent
                      description={description}
                      setDescription={setDescription}
                      markdownRef={markdownRef}
                      placeholder="Program description"
                    />
                  </Box>
                  <Heading size="sm">Short description</Heading>
                  <Input
                    placeholder="Short description"
                    value={shortDescription}
                    onChange={e => {
                      setShortDescription(e.target.value);
                    }}
                    mt={3}
                  />

                  <Box display="flex">
                    <FormControl display="flex" alignItems="center" mt={4} mb={10} gap={2}>
                      <FormLabel htmlFor="isMostPopular" fontWeight="600" m={0}>
                        Is Popular
                      </FormLabel>
                      <Switch
                        id="isMostPopular"
                        colorScheme="black"
                        isChecked={isMostPopular}
                        onChange={e => setIsMostPopular(e.target.checked)}
                      />
                    </FormControl>

                    <FormControl display="flex" alignItems="center" mt={4} mb={10} gap={2}>
                      <FormLabel htmlFor="isNutrition" fontWeight="600" m={0}>
                        Is Nutrition
                      </FormLabel>
                      <Switch
                        id="isNutrition"
                        colorScheme="black"
                        isChecked={isNutrition}
                        onChange={e => setIsNutrition(e.target.checked)}
                      />
                    </FormControl>
                  </Box>

                  <Heading size="sm" pb={3}>
                    Filters
                  </Heading>
                  <Flex>
                    <Select
                      placeholder="Stage"
                      value={stage ?? undefined}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        if (
                          event.target.value === 'pregnant' ||
                          event.target.value === 'postpartum' ||
                          event.target.value === 'trying_to_conceive'
                        ) {
                          setStage(event.target.value);
                        }
                      }}>
                      <option value="pregnant">Pregnant</option>
                      <option value="postpartum">Postpartum</option>
                      <option value="trying_to_conceive">Trying to Conceive</option>
                    </Select>
                    <Select
                      mx={5}
                      placeholder="Level"
                      value={level ?? undefined}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        console.log('event target', event.target.value);
                        setLevel(event.target.value);
                      }}>
                      {[
                        {label: 'All Levels', value: 'all_levels'},
                        {label: 'Beginner', value: 'beginner'},
                        {label: 'Beginner Plus', value: 'beginner_plus'},
                        {label: 'Intermediate', value: 'intermediate'},
                        {label: 'Intermediate Plus', value: 'intermediate_plus'},
                        {label: 'Advanced', value: 'advanced'},
                        {label: 'Beginner-Intermediate', value: 'beginner_intermediate'},
                        {label: 'Intermediate-Advanced', value: 'intermediate_advanced'},
                      ].map(level => (
                        <option key={level.value} value={level.value}>
                          {level.label}
                        </option>
                      ))}
                    </Select>
                    <Input
                      placeholder="Duration"
                      value={duration ?? ''}
                      onChange={e => {
                        setDuration(e.target.value);
                      }}
                      maxLength={12}
                      mb={5}
                    />
                  </Flex>
                  <EquipmentList
                    selectedEquipment={equipmentList}
                    setSelectedEquipment={setEquipmentList}
                  />
                  {hasSurvey && (
                    <Flex
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="start"
                      mt={5}
                      backgroundColor="#FBF4EE"
                      p="16px"
                      borderRadius={12}>
                      <Text fontSize="sm" fontWeight="bold">
                        Survey active on steps:
                      </Text>
                      {surveyDetails.map(survey => (
                        <Flex key={survey.id} gap={2} flexDirection="row" flexWrap="wrap">
                          <BiCheck color="#000000" size={20} />
                          <Text fontSize="sm">
                            Week {survey.week}: Day {survey.day}, Step {survey.step}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </CardBody>
              </Card>
              <Card mb={5}>
                <CardBody p={10}>
                  <PlaylistComponent
                    weeks={weeks}
                    setWeeks={setWeeks}
                    stage={stage}
                    handleGoToVideo={handleGoToVideo}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={2}>
              <Card mb={5}>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Thumbnail
                  </Heading>
                  <Flex justifyContent="center" alignItems="center">
                    <Image
                      src={programThumbnails?.thumbnail ?? thumbnailUrl ?? undefined}
                      fallback={<Box h={32}></Box>}
                    />
                    {/* {programThumbnails ? (
                    ) : (
                      <Box h={32}></Box>
                    )} */}
                    <Button
                      onClick={onOpen}
                      position="absolute"
                      backgroundColor="#80808030">
                      {!!programThumbnails || thumbnailUrl
                        ? 'Change thumbnail'
                        : 'Upload thumbnail'}
                    </Button>
                  </Flex>
                  {!programThumbnails && thumbnailUrl && (
                    <Text mt={2}>Thumbnail has not been reuploaded</Text>
                  )}
                </CardBody>
              </Card>
              {/* <Card mb={5}>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Program access
                  </Heading>
                  <RadioGroup
                    onChange={val => {
                      switch (val) {
                        case '0':
                          setPremium(false);
                          break;
                        case '1':
                          setPremium(true);
                          break;
                      }
                    }}
                    value={premium ? '1' : '0'}>
                    <Stack direction="column">
                      <Radio value={'1'}>Premium</Radio>
                      <Radio value={'0'}>Free</Radio>
                    </Stack>
                  </RadioGroup>
                </CardBody>
              </Card> */}
              <Card>
                <CardBody p={10}>
                  <Heading size="sm" pb={3}>
                    Program status
                  </Heading>
                  <RadioGroup
                    onChange={val => {
                      switch (val) {
                        case '0':
                          setPublished(false);
                          break;
                        case '1':
                          setPublished(true);
                          break;
                      }
                    }}
                    value={published ? '1' : '0'}>
                    <Stack direction="column">
                      <Radio value={'0'}>Unpublished</Radio>
                      <Radio value={'1'}>Published</Radio>
                    </Stack>
                  </RadioGroup>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ProgramDetailsPage;
