import {
  Card,
  CardBody,
  Box,
  Heading,
  Flex,
  Circle,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Link as ChakraLink,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import {ColumnDef, SortingState, createColumnHelper} from '@tanstack/react-table';
import React, {useMemo, useState} from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import ResultsTable from '../../components/ResultsTable/ResultsTable.tsx';
import {LuUser2} from 'react-icons/lu';
import {useQuery} from '@tanstack/react-query';
import Paginator from '../../components/Paginator/Paginator.tsx';
import queryClient from '../../api/queryClient.ts';
import {SearchIcon} from '@chakra-ui/icons';
import UsersTableActionsColumn from '../../components/ResultsTable/Users/UsersTableActionsColumn.tsx';
import {useDebounce} from 'react-use';
import {AxiosInstance} from '../../api/_AxiosInstance.ts';
import {
  GetUsersResponse,
  SingleUser,
  SubscriptionStatus,
} from './types/GetUsersResponse.ts';
import {Link as ReactRouterLink} from 'react-router-dom';
import {Select} from 'chakra-react-select';
import StatsDisplay from './UserStats.tsx';
import {
  stages,
  subscriptionStatuses,
  subscriptionStatusStyle,
  userTypes,
} from './types/usersTypes.ts';

const columnHelper = createColumnHelper<SingleUser>();

interface GetColumnsOptions {
  changeUserRole: (userId: number, roleToSet: string) => void;
  onSubscriptionChange: (userId: number) => void;
}

const getStageLabel = (stage: string) => {
  switch (stage) {
    case 'trying_to_conceive':
      return 'Trying To Conceive';
    case 'pregnant':
      return 'Pregnant';
    case 'postpartum':
      return 'Postpartum';
    default:
      return '';
  }
};

interface FilterOptionsInterface {
  currentPage: number;
  searchQuery: string;
  debouncedSearchQuery: string;
  stage: {value: string; label: string} | null;
  subscriptionStatus: {value: string; label: string} | null;
  userType: {value: string; label: string} | null;
}

const getColumns = (props: GetColumnsOptions) => [
  columnHelper.display({
    header: 'User',
    cell: ({cell}) => (
      <ChakraLink as={ReactRouterLink} to={`/users/view/${cell.row.original.id}`}>
        <Flex alignItems="center">
          <Circle size="32px" bg="#F4DCCB">
            <LuUser2 />
          </Circle>

          <Text color="#333333" ml={2}>
            {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
            {cell.row.original.firstName || cell.row.original.lastName
              ? `${cell.row.original.firstName} ${cell.row.original.lastName}`
              : cell.row.original.email}
          </Text>
        </Flex>
      </ChakraLink>
    ),
  }),
  columnHelper.display({
    header: 'Email',
    cell: ({cell}) => (
      <Flex alignItems="center">
        <Text color="#333333">{cell.row.original.email}</Text>
      </Flex>
    ),
  }),
  columnHelper.display({
    header: 'Current Stage',
    cell: ({cell}) => (
      <Flex alignItems="center">
        <Text color="#333333">{getStageLabel(cell.row.original.currentStage)}</Text>
      </Flex>
    ),
  }),
  columnHelper.display({
    header: 'Subscription Status',
    cell: ({cell}) => {
      const {hasPromotionalSubscription, isRevenueCatPremium, subscriptionStatus} =
        cell.row.original;

      let {backgroundColor, color} = subscriptionStatusStyle[subscriptionStatus];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      let supscriptionType: string = subscriptionStatuses.find(
        s => s.value === subscriptionStatus,
      )!.label;
      if (hasPromotionalSubscription && isRevenueCatPremium) {
        supscriptionType = 'Promotional access, Paid';
        color = '#22543D';
        backgroundColor = '#C6F6D5';
      } else if (hasPromotionalSubscription) {
        supscriptionType = 'Promotional access';
        color = '#22543D';
        backgroundColor = '#C6F6D5';
      }

      return (
        <Flex
          alignItems="center"
          width="fit-content"
          padding={2}
          borderRadius="6px"
          backgroundColor={backgroundColor}>
          <Text color={color ?? '#333333'} fontWeight="500">
            {supscriptionType}
          </Text>
        </Flex>
      );
    },
  }),
  columnHelper.display({
    header: 'Role',
    cell: ({cell}) => (
      <Flex alignItems="center">
        <Text color="#333333">{cell.row.original.role === 'admin' ? 'Admin' : 'User'}</Text>
      </Flex>
    ),
  }),
  columnHelper.display({
    id: 'edit',
    cell: ({cell}) => (
      <UsersTableActionsColumn
        user={cell.row.original}
        changeUserRole={props.changeUserRole}
        onSubscriptionChange={props.onSubscriptionChange}
      />
    ),
  }),
];

export default function UsersPage() {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [filterOptions, setFilterOptions] = useState<FilterOptionsInterface>({
    currentPage: 1,
    searchQuery: '',
    debouncedSearchQuery: '',
    stage: null,
    subscriptionStatus: null,
    userType: null,
  });

  useDebounce(
    () => {
      setFilterOptions(prev => ({
        ...prev,
        currentPage: 1,
        debouncedSearchQuery: filterOptions.searchQuery,
      }));
    },
    500,
    [filterOptions.searchQuery],
  );

  const limit = 15;

  const getAllUsersQuery = useQuery({
    queryKey: [
      'users',
      filterOptions.currentPage,
      filterOptions.debouncedSearchQuery,
      filterOptions.stage,
      filterOptions.subscriptionStatus,
      filterOptions.userType,
    ],
    queryFn: async () => {
      console.log({
        search:
          filterOptions.debouncedSearchQuery !== ''
            ? filterOptions.debouncedSearchQuery
            : null,
        stage: filterOptions.stage?.value,
        subscriptionStatus: filterOptions.subscriptionStatus?.value,
        userType: filterOptions.userType?.value,
      });
      const {data} = await AxiosInstance.get<GetUsersResponse>('/users', {
        params: {
          page: filterOptions.currentPage,
          limit,
          search:
            filterOptions.debouncedSearchQuery !== ''
              ? filterOptions.debouncedSearchQuery
              : null,
          stage: filterOptions.stage?.value,
          subscriptionStatus: filterOptions.subscriptionStatus?.value,
          userType: filterOptions.userType?.value,
        },
      });
      return data;
    },
  });

  const getUserStatisticsQuery = useQuery({
    queryKey: ['userStatistics'],
    queryFn: async () => {
      const {data} = await AxiosInstance.get<Record<string, number>>('/users/statistics');
      const statsData = [
        {label: 'Active Subscriptions', value: data.active ?? 0, status: 'active'},
        {label: 'Free', value: data.free ?? 0, status: 'free'},
        {label: 'Trial', value: data.trial ?? 0, status: 'trial'},
        {label: 'Cancelled', value: data.cancelled ?? 0, status: 'cancelled'},
        {
          label: 'Cancelled Trial',
          value: data.cancelled_trial ?? 0,
          status: 'cancelled_trial',
        },
      ];
      return statsData;
    },
  });

  function changeUserRole(userId: number, roleToSet: string) {
    queryClient.setQueryData(
      [
        'users',
        filterOptions.currentPage,
        filterOptions.debouncedSearchQuery,
        filterOptions.stage,
        filterOptions.subscriptionStatus,
        filterOptions.userType,
      ],
      (oldData: GetUsersResponse) => {
        const newData = oldData.data?.map(user => {
          if (user.id === userId) {
            return {...user, role: roleToSet};
          }
          return user;
        });
        return {
          ...oldData,
          data: newData,
        };
      },
    );
  }

  function toggleUserSubscription(userId: number) {
    queryClient.setQueryData(
      [
        'users',
        filterOptions.currentPage,
        filterOptions.debouncedSearchQuery,
        filterOptions.stage,
        filterOptions.subscriptionStatus,
        filterOptions.userType,
      ],
      (oldData: GetUsersResponse) => {
        const newData = oldData.data?.map(user => {
          if (user.id === userId) {
            return {...user, hasPromotionalSubscription: !user.hasPromotionalSubscription};
          }
          return user;
        });
        return {
          ...oldData,
          data: newData,
        };
      },
    );
  }

  const columns = useMemo(
    () =>
      getColumns({
        changeUserRole,
        onSubscriptionChange: toggleUserSubscription,
      }),
    [
      filterOptions.currentPage,
      filterOptions.debouncedSearchQuery,

      filterOptions.stage,
      filterOptions.subscriptionStatus,
      filterOptions.userType,
    ],
  );

  return (
    <Box height="auto">
      <Flex justifyContent="space-between">
        <Heading mb={4}>Users</Heading>
      </Flex>
      <Card>
        <SimpleGrid
          columns={{xl: 3, sm: 1}}
          mb={0}
          px={10}
          pt={10}
          gap={4}
          justifyContent="space-between">
          <GridItem colSpan={1}>
            <InputGroup flex={1}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                placeholder="Search by title"
                value={filterOptions.searchQuery}
                onChange={e =>
                  setFilterOptions({...filterOptions, searchQuery: e.target.value})
                }
                mb={4}
                borderRadius="5"
                borderColor="gray.300"
                _placeholder={{color: 'gray.400'}}
                _focus={{borderColor: 'blue.500'}}
                pl="10"
                maxWidth="350px"
              />
            </InputGroup>
          </GridItem>
          <GridItem colSpan={2}>
            <Flex flex={1} justifyContent="flex-end" gap={4} flexGrow={1}>
              {/* Stage Dropdown */}
              <Select
                placeholder="Select Stage"
                value={filterOptions.stage}
                onChange={option =>
                  setFilterOptions({...filterOptions, stage: option, currentPage: 1})
                }
                styles={{container: base => ({...base, width: '200px'})}}
                options={stages}
                isClearable
              />

              {/* Subscription Status Dropdown */}
              <Select
                placeholder="Subscription Status"
                value={filterOptions.subscriptionStatus}
                onChange={option =>
                  setFilterOptions({
                    ...filterOptions,
                    subscriptionStatus: option,
                    currentPage: 1,
                  })
                }
                options={subscriptionStatuses}
                isClearable
              />

              {/* User Type Dropdown */}
              <Select
                placeholder="User Type"
                value={filterOptions.userType}
                onChange={option =>
                  setFilterOptions({...filterOptions, userType: option, currentPage: 1})
                }
                options={userTypes}
                isClearable
              />
            </Flex>
          </GridItem>
        </SimpleGrid>
        {getUserStatisticsQuery.data && (
          <StatsDisplay stats={getUserStatisticsQuery.data} />
        )}

        <CardBody>
          <ResultsTable
            columns={columns as ColumnDef<any>[]}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data={(getAllUsersQuery.data?.data as any) ?? []}
            onSortChange={setSorting}
            sorting={sorting}
          />

          {(getAllUsersQuery.data?.lastPage ?? 1) > 1 && (
            <Paginator
              onPageChange={({selected}) => {
                setFilterOptions(prev => ({...prev, currentPage: selected + 1}));
              }}
              pageCount={getAllUsersQuery.data?.lastPage ?? 1}
              forcePage={filterOptions.currentPage - 1}
            />
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
